<template>
	<div class="detailartikel">
		<ArtikelDetail/>
	</div>
</template>
<script>
import ArtikelDetail from "@/components/DetailArtikel.vue";


export default {
	name: "DetailArtikel",
	components: {
		ArtikelDetail
	},
};
</script>
<style></style>
