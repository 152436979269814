<template>
	<div class="container detail-artikel mt-3">
		<div class="container-fluid">
			<div :class="{ hilang: loadingArtikel }" class="col-12 text-center">
				<img
					class="card-img"
					src="../assets/images/testing/load.gif"
					style="width: 10%;!important"
				/>
			</div>
		</div>
		<div class="container-fluid dtl-artikel">
			
			<h5 class="jdl-prod">Artikel</h5>
			<h2 class="bold-stella mt-3 mb-3 jdl-artikel">
				{{ detailArtikel.judul_artikel }}
			</h2>

			<p class="txt-read">
				Telah dibaca 120 kali
			</p>
			<p class="artikel-date-publish">
				{{ detailArtikel.tanggal_publish }}
			</p>
			<!-- <div class="mt-3 mb-3 des-artikel" v-html="detailArtikel.meta_desc"></div> -->

			<img :src="detailArtikel.url_gambar_artikel" width="100%" />

			<!-- random 1 produk stella -->
			<div
				v-if="produkRelateArtikel != null"
				class="container text-center card-columns mx-auto d-flex justify-content-center mt-5 mb-3"
			>
				<div class="col-xs-3 col-sm-6 col-md-3">
					<div class="card box8 ">
						<div class="produk-card-p-s">
							<div class="mt-auto">
								<img
									:src="produkRelateArtikel.url_foto_produk_relate"
									width="35%"
								/>
							</div>
						</div>
						<div :class="{ hilang: loadingRelate }" class="col-12 text-center">
							<img
								class="card-img"
								src="../assets/images/testing/load.gif"
								style="width: 10%;!important"
							/>
						</div>
						<div class="card-footer-p-s text-muted text-p-ls">
							<div class="">
								<p class="text-produk-mix">
									{{ produkRelateArtikel.nama_produk_relate }}
								</p>
							</div>
							<div class="box-content">
								<!-- <button><b-icon-cart3></b-icon-cart3> Beli Sekarang</button
								><br /> -->
								<router-link
									:to="'/produk-detail/' + produkRelateArtikel.slug_relate"
									>Lihat Selengkapnya</router-link
								>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- detail kontent artikel html  -->
			<p v-html="detailArtikel.json_ld"></p>
			<div class="mt-3 des-artikel" v-html="detailArtikel.konten"></div>
			<div class="artikel-line" id="comments">
				<div class="fluid">
					<div class="row">
						<div class="col-lg-12">
							<div class="mt-5 mb-3 txt-read">
								<a @click="copytxt">Share</a>
							</div>
							<form
								v-if="is_log != null"
								class="form-komen"
								@submit="postKomen"
							>
							<h3 class="pull-left-comment mt-3 mb-3 share-comment">
								Tambah Komentar
							</h3>
								<fieldset>
									<div class="row">
										<div class="form-group col-12 col-lg-11">
											<textarea
												class="form-control"
												v-model="komen.isikomentar"
												id="message"
												placeholder="Komentar kamu"
												required=""
											></textarea>
										</div>

										<button type="submit" class="btnKomentar pull-right">
											Kirim
										</button>
									</div>
								</fieldset>
							</form>
							
							<h3 v-else class="pull-left-comment mt-3 mb-3 share-comment">
								Tambah Komentar / <router-link to="/login">Masuk</router-link>
							</h3>
							<h3 class="h-komentar">Komentar</h3>

							<!-- COMMENT 1 - START -->
							<div
								v-for="komenUser in komenUsers"
								:key="komenUser.id_komentar"
								class="media"
							>
								<a class="pull-left-comment"
									><b-icon icon="person-circle" class="u-komentar"></b-icon
								></a>
								<div class="media-body-cmnt col-lg-11 col">
									<h4 class="media-heading-cmnt h-komentar">
										{{ komenUser.name }}
										<span style="float:right;">
											{{ komenUser.created_at }}
										</span>
									</h4>
									<p class="komentar-user">
										{{ komenUser.komentar }}
									</p>
								</div>
							</div>
							<!-- COMMENT 1 - END -->
						</div>
					</div>
				</div>
			</div>
			<div class="fluid mt-5">
				<h4 class="bold-stella jdl-prod">Artikel Terkait</h4>

				<div class="row text-center h-100">
					<div
						v-for="newArtikelRelate in artikelRelateNew"
						:key="newArtikelRelate.id_artikel"
						:class="{ hilang: afterLoading }"
						class="col-sm-3 text-center card-col-artikel "
					>
						<router-link
							class="shadow card-artikel box7 nav-link link-artikel"
							:to="'/artikel/' + newArtikelRelate.slug"
						>
							<div class="card-add">
								<div class="card-body text-left ">
									<p class="card-title artikel-judul-list">
										{{ newArtikelRelate.nama_kategori_artikel }}
									</p>
									<img
										class="card-img"
										:src="newArtikelRelate.url_gambar_artikel"
										width="25%"
									/>
									<p class="card-title artikel-judul-list">
										{{ newArtikelRelate.judul_artikel }}
									</p>
									<p class="card-text artikel-desk-list">
										{{ newArtikelRelate.meta_desc }}
									</p>
								</div>
								<div class="card-artikel-footer">
									<small class="text-muted">{{
										newArtikelRelate.tanggal_publish
									}}</small>
									<small class="text-muted"
										><router-link :to="'artikel/' + newArtikelRelate.slug"
											>Selengkapnya<b-icon-arrow-right
											></b-icon-arrow-right></router-link
									></small>
								</div>
							</div>
							<div class="box-content">
								<div class="card-content-show card-artikel-first ">
									<div class="card-body ">
										<p class="text-left first-pesan">
											{{ newArtikelRelate.judul_artikel }}
										</p>
										<p class="text-left pesan-tanggal">
											{{ newArtikelRelate.tanggal_publish }}
										</p>

										<p class="text-left first-desk">
											{{ newArtikelRelate.meta_desc }}
										</p>
									</div>
								</div>
							</div>
						</router-link>
					</div>

					<div
						:class="{ hilang: loading }"
						class="col-sm-2 text-center card-col-artikel "
					>
						<div class="card shadow card-artikel artikel-list">
							<div class="card-body text-left ">
								<p class="info-skel">tips</p>
								<img
									class="card-img"
									src="../assets/images/testing/load.gif"
									width="10%"
								/>
								<p class="info-skel">
									Judul
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
export default {
	name: "ArtikelDetail",
	data() {
		return {
			is_log: localStorage.getItem("token"),
			detailArtikel: {},
			loadingArtikel: false,
			afterLoadingArtikel: true,
			relateArtikel: [],
			loadingRelate: false,
			afterLoadingRelate: true,
			produkRelateArtikel: {},
			artikelRelateNew: [],
			loading: false,
			afterLoading: true,
			params: "",
			komenUsers: [],
			komen: {
				isikomentar: null,
			},
		};
	},
	methods: {
		getData() {
			this.params = this.$route.params.slug;
			HTTP.get("/detailartikel/" + this.params)
				.then((response) => this.setArtikelDetail(response.data))
				// .then((response) => console.log(response))
				.catch((error) => console.log(error));
			HTTP.get("/artikelrelateproduk/" + this.params)
				.then((response) => {
					if (response.status == "200") {
						this.setProdukArtikelRelate(response.data);
						console.log(response.status);
					} else {
						this.setProdukArtikelRelate(null);
						console.log(response.status);
					}
				})
				// .then((response) => console.log(response.data))
				.catch((error) => console.log(error));
			HTTP.get("/relatedartikel/" + this.params)
				.then((response) => this.setArtikelHome(response.data))
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
			this.params = this.$route.params.slug;
			HTTP.get("/komentarartikel/" + this.params)
				.then((response) => this.setKomenUser(response.data))
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
			this.scroollToTop();
		},
		getDataKomen() {
			this.params = this.$route.params.slug;
			HTTP.get("/komentarartikel/" + this.params)
				.then((response) => this.setKomenUser(response.data))
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
		},
		postKomen(data) {
			const dataKomentar = {
				komentar: this.komen.isikomentar,
				artikel_id: this.detailArtikel.id_artikel,
			};
			// console.log(dataKomentar);
			HTTP.post("/commentpost", dataKomentar, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			})
				// .then((response) => this.setArtikelHome(response.data))
				// jika sukses
				.then((response) => {
					// console.log(response)

					this.getDataKomen();
					this.$toast.success(response.data.msg, {
						type: "success",
						position: "top-right",
						duration: 3000,
						dismissible: true,
					});
				})
				// jika gagal
				.catch((error) => {
					console.log(error);

					this.$toast.error("Anda gagal mengirim komentar", {
						type: "error",
						position: "top-right",
						duration: 3000,
						dismissible: true,
					});
				});
			// after push
			this.komen.isikomentar = "";
			data.preventDefault();
		},
		setArtikelDetail(data) {
			// var descMeta = document.querySelector('head meta[name="description"]');
			// descMeta.setAttribute("content", data.judul_artikel)
			// var ogtype = document.querySelector('head meta[name="description"]');
			// descMeta.setAttribute("content", data.judul_artikel)
			
			this.detailArtikel = data;
			this.loadingArtikel = true;
			this.afterLoadingArtikel = false;
		},
		setProdukArtikelRelate(dataProdukRelate) {
			this.produkRelateArtikel = dataProdukRelate;
			this.loadingRelate = true;
			// this.afterLoadingRelate = false;
		},
		setArtikelHome(data) {
			this.artikelRelateNew = data;
			this.loading = true;
			this.afterLoading = false;
		},
		setKomenUser(data) {
			this.komenUsers = data;
		},
		copytxt() {
			var TempText = document.createElement("input");
			TempText.value = document.URL;
			document.body.appendChild(TempText);
			TempText.select();

			document.execCommand("copy");
			document.body.removeChild(TempText);

			// alert("Copied the text: " + document.URL);
			this.$toast.info("copy link success.", {
				type: "info",
				position: "bottom",
				duration: 3000,
				dismissible: true,
			});
		},
		scroollToTop() {
			window.scrollTo(0, 0);
		},
	},
	created() {
		this.getData();
	},
	watch: {
		"$route.params.slug"() {
			this.loadingArtikel = false;
			this.afterLoadingArtikel = true;
			this.loading = false;
			this.afterLoading = true;
			this.loadingRelate = false;
			this.afterLoadingRelate = true;
			this.getData();
			// console.log(to);
			// console.log(from);
		},
	},
	metaInfo() {
        return { 
            meta: [
                { name: 'description', content:  this.detailArtikel.meta_desc},
                { property: 'og:title', content: this.detailArtikel.judul_artikel},
                { property: 'og:url', content: 'https://stella-airfreshener.com/artikel/'+this.detailArtikel.slug},
                { property: 'og:site_name', content: 'Stella Air Freshner'},
                { property: 'og:type', content: 'article'},    
                { property: 'og:description', content:  this.detailArtikel.meta_desc},
                { property: 'og:image', content:  this.detailArtikel.url_gambar_artikel},
                { property: 'article:published_time', content:  this.detailArtikel.tanggal_publish},
                { name: 'twitter:description', content:  this.detailArtikel.meta_desc},
                { name: 'twitter:title', content:  this.detailArtikel.judul_artikel},
                { name: 'twitter:image', content:  this.detailArtikel.url_gambar_artikel},
                { name: 'robots', content: 'index,follow'} 
            ]
        }
    }
};
</script>

<style></style>
