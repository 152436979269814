<template>
  <div class="container">
			<div class="row mt-3 align-items-center justify-content-center">
				<div class="col-4 col-lg-4 pad-mob" style="color: black; font-weight: initial;">
					<h2 class="bold-stella jdl-prod">Informasi</h2>
					<div class="row">
						<div class=" col-lg-1 ph-mobile in-mobile">
							<b-icon-cursor-fill class="call-footer"></b-icon-cursor-fill>
						</div>
						<div class="col-12 col-lg-11 ph-mobile">
							
							<p align="justify">
								<b-icon-cursor-fill class="call-footer in-webview"></b-icon-cursor-fill>
								Godrej Indonesia Secure Building A, 2nd-3rd Floor, Jl
								Raya
								Protokol Halim Perdanakusuma RT.5/RW.10, Halim
								Perdanakusuma,
								Makassar, East Jakarta City, Jakarta 13610
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-1 ph-mobile in-mobile">
							<b-icon-chat-left-dots-fill
								class="call-footer"
							></b-icon-chat-left-dots-fill>
						</div>
						<div class="col-12 col-lg-11 ph-mobile">
							<p><b-icon-chat-left-dots-fill
								class="call-footer in-webview"
							></b-icon-chat-left-dots-fill>
								contact@stella-airfreshener
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col-1 col-lg-1 ph-mobile in-mobile">
							<b-icon-telephone-fill
								class="call-footer"
							></b-icon-telephone-fill>
						</div>
						<div class="col-12 col-lg-11 ph-mobile">
							<p><b-icon-telephone-fill
								class="call-footer in-webview"
							></b-icon-telephone-fill>
								(+621) 89012345
							</p>
						</div>
					</div>
				</div>
				<div class="col-8 col-lg-8 container formBox">
					<h2 class="bold-stella jdl-prod" style="color:black">Kirim Pesan</h2>

					<form class="mt-3" @submit="postPesan" method="post">
						<div class="row">
							<div class="col-12 col-lg-6">
								<div class="inputBox">
									<div class="inputForm">Nama</div>
									<input
										placeholder="nama"
										type="text"
										name="nama"
										class="input"
										v-model="postsContact.nama"
										required
									/>
								</div>
							</div>
							<div class="col-12 col-lg-6">
								<div class="inputBox">
									<div class="inputForm">Email</div>
									<input
										placeholder="email"
										type="text"
										name="email"
										class="input"
										v-model="postsContact.email"
										required
									/>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-12 col-lg-6">
								<div class="inputBox">
									<div class="inputForm">Jenis Kelamin</div>
									<select v-model="postsContact.gender" class="input">
										<option :value="null" disabled selected>jenis kelamin</option>
										<option>Laki-laki</option>
										<option>Perempuan</option>
									</select>
								</div>
							</div>
							
							<div class="col-12 col-lg-6">
								<div class="inputBox">
									<div class="inputForm">Nomor Hp</div>
									<input
										placeholder="Nomor Hp"
										type="text"
										name=""
										class="input"
										v-model="postsContact.phone"
										required
									/>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-lg-12">
								<div class="inputBox">
									<div class="inputForm">Pesan</div>
									<textarea
										type="text"
										name="isi_pesan"
										rows="3"
										class="form-control md-textarea data-prof"
										v-model="postsContact.isi_pesan"
										required
									>
									</textarea>
								</div>
							</div>
						</div>

						<div class="text-left">
							<input type="submit" name="" class="btnSubs" value="Kirim" />
						</div>
					</form>
				</div>
			</div>
		</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";

export default {
	name: "ContactUs",
	data(){
		return{
			
			postsContact: {
				nama: null,
				email: null,
				gender: null,
				phone: null,
				isi_pesan: null,
			},
		}
	},
	methods:{
		postPesan(data) {
			HTTP.post("kirimpesan", this.postsContact)
				// .then((response) => this.setArtikelHome(response.data))
				// jika sukses
				// console.log(this.postsContact)
				.then((response) => {
					console.log(response.status);
					// this.afterSubs = false;
					// this.beforeSubs = true;
					this.$toast.success("Anda berhasil mengirim Pesan. terimakasih.", {
						type: "success",
						position: "top-right",
						duration: 3000,
						dismissible: true,
					});
				})
				// jika gagal
				.catch((error) => {
					console.log(error.response.status);

					this.$toast.error("Anda gagal gagal mengirim pesan. silahkan ulangi.", {
						type: "error",
						position: "top-right",
						duration: 3000,
						dismissible: true,
					});
				});
			this.postsContact.nama = "";
			this.postsContact.email = "";
			this.postsContact.gender = null;
			this.postsContact.phone = "";
			this.postsContact.isi_pesan = "";
			data.preventDefault();
		},
		scroollToTop(){
			window.scrollTo(0,0)
		}
	},
	created(){
		this.scroollToTop();
	}
	
};
</script>

<style>

</style>