<template>
	<div class="fluid">
		<div class="container all-produk">
			<h5 class="bold-stella m-1">Produk</h5>

			<div class="row">
				<div class="col-12 mb-2 web-view">
					<b-button class="btnFilter" v-b-modal.modal-xl variant="outline-info"
						><b-icon icon="sliders"></b-icon> Filter</b-button
					>
				</div>
				<div class="col-4 col-lg-2 mobile-view">
					<p class="sub-judul m-1">Filter</p>

					<p v-b-toggle.collapse-3 class="sub-judul-1 m-1">
						Format <b-icon icon="caret-down-fill"></b-icon>
					</p>
					<b-collapse
						v-for="newFT in formatTipeNew"
						:key="newFT.id_format_tipe"
						visible
						id="collapse-3"
						class="clp-filter"
					>
						<div class="chiller_cb">
							<input
								:id="newFT.id_format_tipe"
								type="checkbox"
								:value="newFT.id_format_tipe"
								v-model="tFormat"
								v-on:checked="filterProdukAll"
							/>
							<label :for="newFT.id_format_tipe">{{
								newFT.nama_format_tipe
							}}</label>
							<span></span>
						</div>
					</b-collapse>
					<p v-b-toggle.ls class="sub-judul-1 m-1 iconFilter">
						Living Space <b-icon icon="caret-down-fill"></b-icon>
					</p>
					<b-collapse
						v-for="newLS in livingSpaceNew"
						:key="'ls' + newLS.id_living_space"
						visible
						id="ls"
						class="clp-filter"
					>
						<div class="chiller_cb">
							<input
								:id="'ls' + newLS.id_living_space"
								type="checkbox"
								:value="newLS.id_living_space"
								v-model="tLivngSpace"
								v-on:checked="filterProdukAll"
							/>
							<label :for="'ls' + newLS.id_living_space">{{
								newLS.nama_living_space
							}}</label>
							<span></span>
						</div>
					</b-collapse>
				</div>
				<div class="col-lg-10 col-12 tab-prod">
					<b-tabs content-class="mt-3">
						<b-tab title="Reguler">
							<div class="fluid mb-3">
								<img
									class="img-banner-prod mobile-view"
									:src="bannerReg"
									width="100%"
								/>
							</div>
							<div class="fluid mb-3 web-view">
								<img
									class="img-banner-prod"
									:src="bannerRegMobile"
									width="100%"
								/>
							</div>
							<div class="input-group mb-3">
								<input
									v-model="regProdukSearch"
									type="text"
									class="form-control txt-search"
									placeholder="Cari Produk"
									aria-label="Cari Produk"
									aria-describedby="basic-addon1"
								/>
								<span class="input-group-text logo-search"
									><b-icon-search></b-icon-search
								></span>
							</div>
							<div class="fluid mb-3">
								<div class="row text-center h-100">
									<div
										v-if="filterProdukReg.length == 0"
										:class="{ hilang: afterLoadingReg }"
										class="col-12 col-lg-12 text-center card-produk-stella mt-3"
									>
										<p>
											Produk Pilihanmu tidak tersedia, Silahkan pilih kembali
										</p>
									</div>
									<!-- loop prod dari sini -->
									<div
										v-else
										v-for="newProdukReg in filterProdukReg"
										:key="newProdukReg.id_produk"
										:class="{ hilang: afterLoadingReg }"
										class="col-6 col-lg-3 text-center card-produk-stella"
									>
										<div class="card box-prod">
											<div v-if="is_like != null" class="card-header p-love">
												<a
													@click="likeBtn(newProdukReg.id_produk)"
													v-if="newProdukReg.id_wishlist_user != null"
												>
													<b-icon-heart
														:class="
															'reg-like' + newProdukReg.id_produk + ' hilang'
														"
													></b-icon-heart>
													<b-icon-heart-fill
														:class="'reg-unlike' + newProdukReg.id_produk"
													></b-icon-heart-fill>
												</a>
												<a v-else @click="likeBtn(newProdukReg.id_produk)">
													<b-icon-heart
														:class="'reg-like' + newProdukReg.id_produk"
													></b-icon-heart>
													<b-icon-heart-fill
														:class="
															'reg-unlike' + newProdukReg.id_produk + ' hilang'
														"
													></b-icon-heart-fill>
												</a>
											</div>
											<div v-else class="card-header p-love"></div>
											<div class="produk-card-p-s">
												<div
													v-if="newProdukReg.foto_landscape == 1"
													class="pp mt-auto"
												>
													<img
														:src="
															'https://stellacms.stella-airfreshener.com/' +
																newProdukReg.url_foto_produk
														"
													/>
												</div>
												<div
													v-else-if="newProdukReg.foto_landscape == 2"
													class="ps mt-auto"
												>
													<img
														:src="
															'https://stellacms.stella-airfreshener.com/' +
																newProdukReg.url_foto_produk
														"
													/>
												</div>
												<div
													v-else-if="newProdukReg.foto_landscape == 3"
													class="pl mt-auto"
												>
													<img
														:src="
															'https://stellacms.stella-airfreshener.com/' +
																newProdukReg.url_foto_produk
														"
													/>
												</div>
											</div>
											<div class="card-footer-p-s text-muted text-p-ls">
												<div class="">
													<p class="text-produk-mix-p">
														{{ newProdukReg.nama_produk }}
													</p>
												</div>
												<div class="box-content">
													<button
														class="btnSubs"
														@click="$bvModal.show('modal-store-produk-all')"
													>
														<b-icon-cart3></b-icon-cart3> Beli Sekarang</button
													><br />
													<router-link
														:to="'produk-detail/' + newProdukReg.slug"
														>Lihat Selengkapnya</router-link
													>
												</div>
											</div>
										</div>
									</div>
									<!-- sampai sini  -->
								</div>
								<div :class="{ hilang: loadingReg }" class="col-12 text-center">
									<div class="">
										<img
											class="card-img"
											src="../assets/images/testing/load.gif"
											style="width: 10%;!important"
										/>
									</div>
								</div></div
						></b-tab>
						<b-tab title="Parfum'ist">
							<div class="fluid mb-3">
								<img
									class="img-banner-prod mobile-view"
									:src="bannerParf"
									width="100%"
								/>
							</div>
							<div class="fluid mb-3">
								<img
									class="img-banner-prod web-view"
									:src="bannerParfMobile"
									width="100%"
								/>
							</div>
							<div class="input-group mb-3">
								<input
									v-model="parfProdukSearch"
									type="text"
									class="form-control txt-search"
									placeholder="Cari Produk"
									aria-label="Cari Produk"
									aria-describedby="basic-addon1"
								/>
								<span class="input-group-text logo-search"
									><b-icon-search></b-icon-search
								></span>
							</div>
							<div class="fluid mb-3">
								<div class="row text-center h-100">
									<div
										v-if="filterProdukParf.length == 0"
										:class="{ hilang: afterLoadingParf }"
										class="col-12 col-lg-12 text-center card-produk-stella mt-3"
									>
										<p>
											Produk Pilihanmu tidak tersedia, Silahkan pilih kembali
										</p>
									</div>
									<!-- loop prod dari sini -->
									<div
										v-else
										v-for="newProdukParf in filterProdukParf"
										:key="newProdukParf.id_produk"
										:class="{ hilang: afterLoadingParf }"
										class="col-6 col-lg-3 text-center card-produk-stella"
									>
										<div class="card box-prod">
											<div v-if="is_like != null" class="card-header p-love">
												<a
													@click="likeBtn(newProdukParf.id_produk)"
													v-if="newProdukParf.id_wishlist_user != null"
												>
													<b-icon-heart
														:class="
															'parf-like' + newProdukParf.id_produk + ' hilang'
														"
													></b-icon-heart>
													<b-icon-heart-fill
														:class="'parf-unlike' + newProdukParf.id_produk"
													></b-icon-heart-fill>
												</a>
												<a v-else @click="likeBtn(newProdukParf.id_produk)">
													<b-icon-heart
														:class="'parf-like' + newProdukParf.id_produk"
													></b-icon-heart>
													<b-icon-heart-fill
														:class="
															'parf-unlike' +
																newProdukParf.id_produk +
																' hilang'
														"
													></b-icon-heart-fill>
												</a>
											</div>
											<div v-else class="card-header p-love"></div>
											<div class="produk-card-p-s">
												<div
													v-if="newProdukParf.foto_landscape == 1"
													class="pp mt-auto"
												>
													<img
														:src="
															'https://stellacms.stella-airfreshener.com/' +
																newProdukParf.url_foto_produk
														"
													/>
												</div>
												<div
													v-else-if="newProdukParf.foto_landscape == 2"
													class="ps mt-auto"
												>
													<img
														:src="
															'https://stellacms.stella-airfreshener.com/' +
																newProdukParf.url_foto_produk
														"
													/>
												</div>
												<div
													v-else-if="newProdukParf.foto_landscape == 3"
													class="pl mt-auto"
												>
													<img
														:src="
															'https://stellacms.stella-airfreshener.com/' +
																newProdukParf.url_foto_produk
														"
													/>
												</div>
											</div>
											<div class="card-footer-p-s text-muted text-p-ls">
												<div class="">
													<p class="text-produk-mix-p">
														{{ newProdukParf.nama_produk }}
													</p>
												</div>
												<div class="box-content">
													<button
														class="btnSubs"
														@click="$bvModal.show('modal-store-produk-all')"
													>
														<b-icon-cart3></b-icon-cart3> Beli Sekarang</button
													><br />
													<router-link
														:to="'produk-detail/' + newProdukParf.slug"
														>Lihat Selengkapnya</router-link
													>
												</div>
											</div>
										</div>
									</div>
									<!-- sampai sini  -->
								</div>
								<div
									:class="{ hilang: loadingParf }"
									class="col-12 text-center"
								>
									<div class="">
										<img
											class="card-img"
											src="../assets/images/testing/load.gif"
											style="width: 10%;!important"
										/>
									</div>
								</div>
							</div>
						</b-tab>
						<b-tab title="Fresh & Protect">
							<div class="fluid mb-3">
								<img
									class="img-banner-prod mobile-view"
									:src="bannerFnp"
									width="100%"
								/>
							</div>
							<div class="fluid mb-3">
								<img
									class="img-banner-prod web-view"
									:src="bannerFnpMobile"
									width="100%"
								/>
							</div>
							<div class="input-group mb-3">
								<input
									v-model="fnpProdukSearch"
									type="text"
									class="form-control txt-search"
									placeholder="Cari Produk"
									aria-label="Cari Produk"
									aria-describedby="basic-addon1"
								/>
								<span class="input-group-text logo-search"
									><b-icon-search></b-icon-search
								></span>
							</div>
							<div class="fluid mb-3">
								<div class="row text-center h-100">
									<div
										v-if="filterProdukFnp.length == 0"
										:class="{ hilang: afterLoadingFnp }"
										class="col-12 col-lg-12 text-center card-produk-stella mt-3"
									>
										<p>
											Produk Pilihanmu tidak tersedia, Silahkan pilih kembali
										</p>
									</div>
									<!-- loop prod dari sini -->
									<div
										v-else
										v-for="newProdukFnp in filterProdukFnp"
										:key="newProdukFnp.id_produk"
										:class="{ hilang: afterLoadingFnp }"
										class="col-6 col-lg-3 text-center card-produk-stella"
									>
										<div class="card box-prod">
											<div v-if="is_like != null" class="card-header p-love">
												<a
													@click="likeBtn(newProdukFnp.id_produk)"
													v-if="newProdukFnp.id_wishlist_user != null"
												>
													<b-icon-heart
														:class="
															'fnp-like' + newProdukFnp.id_produk + ' hilang'
														"
													></b-icon-heart>
													<b-icon-heart-fill
														:class="'fnp-unlike' + newProdukFnp.id_produk"
													></b-icon-heart-fill>
												</a>
												<a v-else @click="likeBtn(newProdukFnp.id_produk)">
													<b-icon-heart
														:class="'fnp-like' + newProdukFnp.id_produk"
													></b-icon-heart>
													<b-icon-heart-fill
														:class="
															'fnp-unlike' + newProdukFnp.id_produk + ' hilang'
														"
													></b-icon-heart-fill>
												</a>
											</div>
											<div v-else class="card-header p-love"></div>
											<div class="produk-card-p-s">
												<div
													v-if="newProdukFnp.foto_landscape == 1"
													class="pp mt-auto"
												>
													<img
														:src="
															'https://stellacms.stella-airfreshener.com/' +
																newProdukFnp.url_foto_produk
														"
													/>
												</div>
												<div
													v-else-if="newProdukFnp.foto_landscape == 2"
													class="ps mt-auto"
												>
													<img
														:src="
															'https://stellacms.stella-airfreshener.com/' +
																newProdukFnp.url_foto_produk
														"
													/>
												</div>
												<div
													v-else-if="newProdukFnp.foto_landscape == 3"
													class="pl mt-auto"
												>
													<img
														:src="
															'https://stellacms.stella-airfreshener.com/' +
																newProdukFnp.url_foto_produk
														"
													/>
												</div>
											</div>
											<div class="card-footer-p-s text-muted text-p-ls">
												<div class="">
													<p class="text-produk-mix-p">
														{{ newProdukFnp.nama_produk }}
													</p>
												</div>
												<div class="box-content">
													<button
														class="btnSubs"
														@click="$bvModal.show('modal-store-produk-all')"
													>
														<b-icon-cart3></b-icon-cart3> Beli Sekarang</button
													><br />
													<router-link
														:to="'produk-detail/' + newProdukFnp.slug"
														>Lihat Selengkapnya</router-link
													>
												</div>
											</div>
										</div>
									</div>
									<!-- sampai sini  -->
								</div>
								<div :class="{ hilang: loadingFnp }" class="col-12 text-center">
									<div class="">
										<img
											class="card-img"
											src="../assets/images/testing/load.gif"
											style="width: 10%;!important"
										/>
									</div>
								</div>
							</div>
						</b-tab>
						<b-tab title="Semua" active>
							<div class="input-group mb-3">
								<input
									v-model="allProdukSearch"
									type="text"
									class="form-control txt-search"
									placeholder="Cari Produk"
									aria-label="Cari Produk"
									aria-describedby="basic-addon1"
								/>
								<span class="input-group-text logo-search"
									><b-icon-search></b-icon-search
								></span>
							</div>
							<div class="fluid mb-3">
								<div class="row text-center h-100">
									<div
										v-if="filterProdukAll.length == 0"
										:class="{ hilang: afterLoading }"
										class="col-12 col-lg-12 text-center card-produk-stella mt-3"
									>
										<p>
											Produk Pilihanmu tidak tersedia, Silahkan pilih kembali
										</p>
									</div>
									<!-- loop prod dari sini -->
									<div
										v-else
										v-for="newProdukAll in filterProdukAll"
										:key="newProdukAll.id_produk"
										:class="{ hilang: afterLoading }"
										class="col-6 col-lg-3 text-center card-produk-stella"
									>
										<div class="card box-prod">
											<div v-if="is_like != null" class="card-header p-love">
												<a
													@click="likeBtn(newProdukAll.id_produk)"
													v-if="newProdukAll.id_wishlist_user != null"
												>
													<b-icon-heart
														:class="'like' + newProdukAll.id_produk + ' hilang'"
													></b-icon-heart>
													<b-icon-heart-fill
														:class="'unlike' + newProdukAll.id_produk"
													></b-icon-heart-fill>
												</a>
												<a v-else @click="likeBtn(newProdukAll.id_produk)">
													<b-icon-heart
														:class="'like' + newProdukAll.id_produk"
													></b-icon-heart>
													<b-icon-heart-fill
														:class="
															'unlike' + newProdukAll.id_produk + ' hilang'
														"
													></b-icon-heart-fill>
												</a>
											</div>
											<div v-else class="card-header p-love"></div>
											<div class="produk-card-p-s">
												<div
													v-if="newProdukAll.foto_landscape == 1"
													class="pp mt-auto"
												>
													<img
														:src="
															'https://stellacms.stella-airfreshener.com/' +
																newProdukAll.url_foto_produk
														"
													/>
												</div>
												<div
													v-else-if="newProdukAll.foto_landscape == 2"
													class="ps mt-auto"
												>
													<img
														:src="
															'https://stellacms.stella-airfreshener.com/' +
																newProdukAll.url_foto_produk
														"
													/>
												</div>
												<div
													v-else-if="newProdukAll.foto_landscape == 3"
													class="pl mt-auto"
												>
													<img
														:src="
															'https://stellacms.stella-airfreshener.com/' +
																newProdukAll.url_foto_produk
														"
													/>
												</div>
											</div>
											<div class="card-footer-p-s text-muted text-p-ls">
												<div class="">
													<p class="text-produk-mix-p">
														{{ newProdukAll.nama_produk }}
													</p>
												</div>
												<div class="box-content">
													<button
														class="btnSubs"
														@click="$bvModal.show('modal-store-produk-all')"
													>
														<b-icon-cart3></b-icon-cart3> Beli Sekarang</button
													><br />
													<router-link
														:to="'produk-detail/' + newProdukAll.slug"
														>Lihat Selengkapnya</router-link
													>
												</div>
											</div>
										</div>
									</div>
									<!-- sampai sini  -->
								</div>
								<div
									:class="{ hilang: loading }"
									class="col-12 col-lg-12 text-center"
								>
									<div class="">
										<img
											class="card-img"
											src="../assets/images/testing/load.gif"
											style="width: 10%;!important"
										/>
									</div>
								</div></div
						></b-tab>
					</b-tabs>
				</div>
			</div>
		</div>
		<b-modal size="lg" ref="blnjaModalProduk" id="modal-store-produk-all" centered hide-header hide-footer>
			<div class="d-block text-center store-belanja">
				<div class="col-12 col-lg-12">
					<b-button
						style="position: absolute;right:5%"
						class="close mdl-blnj"
						@click="hideModalbelanjaproduk"
						>x</b-button
					>
					<h3 class="bold-stella">SHOP</h3>
					<p>OFFICIAL STORE</p>
				</div>
				<div class="col-12 col-lg-12">
					<div class="container">
						<div class="row">
							<div
								v-for="newSP in storeoficialNewAll"
								:key="newSP.id_ecommerce"
								class="col-3 col-lg-3 text-center"
							>
								<a
									class="navbar-brand tengah-store"
									:href="'//' + newSP.link_ecommerce_official"
								>
									<img :src="newSP.link_logo_official" width="100%" />
								</a>
								<p v-if="newSP.updiscount != 0">{{ newSP.nama_ecommerce }} {{ newSP.updiscount }}% </p>
								<p v-else>{{ newSP.nama_ecommerce }} </p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal
			content-class="filterMobile"
			ref="modalFilterMobile"
			id="modal-xl"
			hide-header
			hide-footer
			scrollable
		>
			<div class="row col-lg-12">
				<div class="col-12 justify-content-between p-0">
					<p class="ml-3 sub-judul mb-1">
						Filter

						<b-button
							class="btnFilter"
							style="float:right"
							@click="hideModal"
							variant="outline-info"
							>Terapkan</b-button
						>
					</p>
				</div>
				<div class="col-12">
					<p v-b-toggle.collapse-3 class="sub-judul-1 ">
						Format <b-icon icon="caret-down-fill"></b-icon>
					</p>
					<b-collapse
						v-for="newFT in formatTipeNew"
						:key="newFT.id_format_tipe"
						visible
						id="collapse-3"
						class="clp-filter"
					>
						<div class="chiller_cb">
							<input
								:id="newFT.id_format_tipe"
								type="checkbox"
								:value="newFT.id_format_tipe"
								v-model="tFormat"
								v-on:checked="filterProdukAll"
							/>
							<label :for="newFT.id_format_tipe">{{
								newFT.nama_format_tipe
							}}</label>
							<span></span>
						</div>
					</b-collapse>
					<p v-b-toggle.ls class="sub-judul-1">
						Living Space <b-icon icon="caret-down-fill"></b-icon>
					</p>
					<b-collapse
						v-for="newLS in livingSpaceNew"
						:key="'ls' + newLS.id_living_space"
						visible
						id="ls"
						class="clp-filter"
					>
						<div class="chiller_cb">
							<input
								:id="'ls' + newLS.id_living_space"
								type="checkbox"
								:value="newLS.id_living_space"
								v-model="tLivngSpace"
								v-on:checked="filterProdukAll"
							/>
							<label :for="'ls' + newLS.id_living_space">{{
								newLS.nama_living_space
							}}</label>
							<span></span>
						</div>
					</b-collapse>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
export default {
	name: "AllProduk",
	data() {
		return {
			is_like: localStorage.getItem("token"),
			produkAllNew: [],
			allProdukSearch: "",
			loading: false,
			afterLoading: true,
			formatTipeNew: [],
			livingSpaceNew: [],
			tFormat: [],
			tLivngSpace: [],
			produkRegNew: [],
			bannerReg: "",
			bannerRegMobile: "",
			loadingReg: false,
			afterLoadingReg: true,
			regProdukSearch: "",
			produkParfNew: [],
			bannerParf: "",
			bannerParfMobile: "",
			loadingParf: false,
			afterLoadingParf: true,
			parfProdukSearch: "",
			produkFnpNew: [],
			bannerFnp: "",
			bannerFnpMobile: "",
			loadingFnp: false,
			afterLoadingFnp: true,
			fnpProdukSearch: "",
			storeoficialNewAll: [],
		};
	},
	methods: {
		hideModalbelanjaproduk() {
			this.$refs["blnjaModalProduk"].hide();
		},
		hideModal() {
			this.$refs["modalFilterMobile"].hide();
		},
		setFormatType(dataFormat) {
			this.formatTipeNew = dataFormat;
		},
		setLivingSpace(dataLivingSpace) {
			this.livingSpaceNew = dataLivingSpace;
		},
		setProdukAll(data) {
			this.produkAllNew = data;
			this.loading = true;
			this.afterLoading = false;
		},
		setProdukReg(dataReg, bannerReg, bannerRegMobile) {
			this.produkRegNew = dataReg;
			this.bannerReg = bannerReg;
			this.bannerRegMobile = bannerRegMobile;
			this.loadingReg = true;
			this.afterLoadingReg = false;
		},
		setProdukParf(dataParf, bannerParf, bannerParfMobile) {
			this.produkParfNew = dataParf;
			this.bannerParf = bannerParf;
			this.bannerParfMobile = bannerParfMobile;
			this.loadingParf = true;
			this.afterLoadingParf = false;
		},
		setProdukFnp(dataFnp, bannerFnp, bannerFnpMobile) {
			this.produkFnpNew = dataFnp;
			this.bannerFnp = bannerFnp;
			this.bannerFnpMobile = bannerFnpMobile;
			this.loadingFnp = true;
			this.afterLoadingFnp = false;
		},
		likeBtn(id_data) {
			var like = document.getElementsByClassName("like" + id_data);
			var unlike = document.getElementsByClassName("unlike" + id_data);

			var fnplike = document.getElementsByClassName("fnp-like" + id_data);
			var fnpunlike = document.getElementsByClassName("fnp-unlike" + id_data);

			var parflike = document.getElementsByClassName("parf-like" + id_data);
			var parfunlike = document.getElementsByClassName("parf-unlike" + id_data);

			var reglike = document.getElementsByClassName("reg-like" + id_data);
			var regunlike = document.getElementsByClassName("reg-unlike" + id_data);
			// untuk tampilan all
			if (like[0].className.baseVal.includes("hilang") == false) {
				like[0].classList.add("hilang");
				unlike[0].classList.remove("hilang");
			} else {
				unlike[0].classList.add("hilang");
				like[0].classList.remove("hilang");
			}
			// untuk kategori fnp
			if (fnplike.length != 0) {
				if (fnplike[0].className.baseVal.includes("hilang") == false) {
					fnplike[0].classList.add("hilang");
					fnpunlike[0].classList.remove("hilang");
				} else if (fnplike[0].className.baseVal.includes("hilang") == true) {
					fnpunlike[0].classList.add("hilang");
					fnplike[0].classList.remove("hilang");
				}
			}

			// untuk kategori parf
			if (parflike.length != 0) {
				if (parflike[0].className.baseVal.includes("hilang") == false) {
					parflike[0].classList.add("hilang");
					parfunlike[0].classList.remove("hilang");
				} else if (parflike[0].className.baseVal.includes("hilang") == true) {
					parfunlike[0].classList.add("hilang");
					parflike[0].classList.remove("hilang");
				}
			}

			// untuk kategori reg
			if (reglike.length != 0) {
				if (reglike[0].className.baseVal.includes("hilang") == false) {
					reglike[0].classList.add("hilang");
					regunlike[0].classList.remove("hilang");
				} else if (reglike[0].className.baseVal.includes("hilang") == true) {
					regunlike[0].classList.add("hilang");
					reglike[0].classList.remove("hilang");
				}
			}

			HTTP.get("/likeproduk/" + id_data, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			})
				// .then((response) => this.setArtikelHome(response.data))
				// jika sukses
				.then((response) => {
					// console.log(response)
					this.$toast.success(response.data.msg, {
						type: "success",
						position: "top-right",
						duration: 3000,
						dismissible: true,
					});
				})
				// jika gagal
				.catch((error) => {
					console.log(error);

					this.$toast.error("Anda gagal.", {
						type: "error",
						position: "top-right",
						duration: 3000,
						dismissible: true,
					});
				});
		},
		setStoreOfficialAll(datastore) {
			this.storeoficialNewAll = datastore;
		},
	},
	created() {
		HTTP.get("all_type_format_tipe")
			.then((response) => this.setFormatType(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
		HTTP.get("all_type_living_space")
			.then((response) => this.setLivingSpace(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));

		if (localStorage.getItem("token") != null) {
			HTTP.get("produklogin/all", {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			})
				.then((response) => this.setProdukAll(response.data.produk))
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
			HTTP.get("produklogin/reg", {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			})
				.then((response) =>
					this.setProdukReg(
						response.data.produk,
						response.data.banner_produk,
						response.data.banner_produk_mobile
					)
				)
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
			HTTP.get("produklogin/parf", {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			})
				.then((response) =>
					this.setProdukParf(
						response.data.produk,
						response.data.banner_produk,
						response.data.banner_produk_mobile
					)
				)
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
			HTTP.get("produklogin/fnp", {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			})
				.then((response) =>
					this.setProdukFnp(
						response.data.produk,
						response.data.banner_produk,
						response.data.banner_produk_mobile
					)
				)
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
			HTTP.get("official_eccommerce")
				.then((response) => this.setStoreOfficialAll(response.data))
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
		} else {
			HTTP.get("produk/all")
				.then((response) => this.setProdukAll(response.data.produk))
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
			HTTP.get("produk/reg")
				.then((response) =>
					this.setProdukReg(
						response.data.produk,
						response.data.banner_produk,
						response.data.banner_produk_mobile
					)
				)
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
			HTTP.get("produk/parf")
				.then((response) =>
					this.setProdukParf(
						response.data.produk,
						response.data.banner_produk,
						response.data.banner_produk_mobile
					)
				)
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
			HTTP.get("produk/fnp")
				.then((response) =>
					this.setProdukFnp(
						response.data.produk,
						response.data.banner_produk,
						response.data.banner_produk_mobile
					)
				)
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
			HTTP.get("official_eccommerce")
				.then((response) => this.setStoreOfficialAll(response.data))
				// .then((response)=> console.log(response.data))
				.catch((error) => console.log(error));
		}
	},
	computed: {
		filterProdukAll: function() {
			if (!this.tFormat.length && !this.tLivngSpace.length) {
				return this.produkAllNew.filter((newProdukAll) => {
					return newProdukAll.nama_produk
						.toLowerCase()
						.match(this.allProdukSearch.toLowerCase());
				});
			} else {
				return this.produkAllNew.filter((newProdukAll) => {
					var allProduk =
						this.tFormat.every((i) =>
							newProdukAll.format_tipe_id.includes(i)
						) &&
						this.tLivngSpace.every((j) =>
							newProdukAll.living_space_id.includes(j)
						) &&
						newProdukAll.nama_produk
							.toLowerCase()
							.match(this.allProdukSearch.toLowerCase());
					return allProduk;
					// return (
					// 	this.tFormat.every((i) =>
					// 		newProdukAll.format_tipe_id.includes(i)
					// 	) &&
					// 	this.tLivngSpace.every((j) =>
					// 		newProdukAll.living_space_id.includes(j)
					// 	) &&
					// 	newProdukAll.nama_produk
					// 		.toLowerCase()
					// 		.match(this.allProdukSearch.toLowerCase())
					// );
					// return (product.price > 0 && product.price < this.range) ? product : '';
				});
			}
		},
		filterProdukReg: function() {
			if (!this.tFormat.length && !this.tLivngSpace.length) {
				return this.produkRegNew.filter((newProdukReg) => {
					return newProdukReg.nama_produk
						.toLowerCase()
						.match(this.regProdukSearch.toLowerCase());
				});
			} else {
				return this.produkRegNew.filter((newProdukReg) => {
					return (
						this.tFormat.every((i) =>
							newProdukReg.format_tipe_id.includes(i)
						) &&
						this.tLivngSpace.every((j) =>
							newProdukReg.living_space_id.includes(j)
						) &&
						newProdukReg.nama_produk
							.toLowerCase()
							.match(this.regProdukSearch.toLowerCase())
					);
				});
			}
		},
		filterProdukParf: function() {
			if (!this.tFormat.length && !this.tLivngSpace.length) {
				return this.produkParfNew.filter((newProdukParf) => {
					return newProdukParf.nama_produk
						.toLowerCase()
						.match(this.parfProdukSearch.toLowerCase());
				});
			} else {
				return this.produkParfNew.filter((newProdukParf) => {
					return (
						this.tFormat.every((i) =>
							newProdukParf.format_tipe_id.includes(i)
						) &&
						this.tLivngSpace.every((j) =>
							newProdukParf.living_space_id.includes(j)
						) &&
						newProdukParf.nama_produk
							.toLowerCase()
							.match(this.parfProdukSearch.toLowerCase())
					);
				});
			}
		},
		filterProdukFnp: function() {
			if (!this.tFormat.length && !this.tLivngSpace.length) {
				return this.produkFnpNew.filter((newProdukFnp) => {
					return newProdukFnp.nama_produk
						.toLowerCase()
						.match(this.fnpProdukSearch.toLowerCase());
				});
			} else {
				return this.produkFnpNew.filter((newProdukFnp) => {
					return (
						this.tFormat.every((i) =>
							newProdukFnp.format_tipe_id.includes(i)
						) &&
						this.tLivngSpace.every((j) =>
							newProdukFnp.living_space_id.includes(j)
						) &&
						newProdukFnp.nama_produk
							.toLowerCase()
							.match(this.fnpProdukSearch.toLowerCase())
					);
				});
			}
		},
	},
};
</script>

<style></style>
