<template>
	<div class="fluid stella-second">
		<b-navbar toggleable="lg">
			<b-navbar-brand class="web-view" href="/"
				><img src="../assets/images/logo-stella.png" width="100%"
			/></b-navbar-brand>
			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav>
					<b-navbar-brand class="mobile-view" href="/"
						><img src="../assets/images/logo-stella.png" width="100%"
					/></b-navbar-brand>
					<router-link class="nav-link" to="/">Beranda</router-link>
					<router-link class="nav-link" to="/produk">Produk</router-link>
					<router-link class="nav-link" to="/update">Terbaru</router-link>
					<b-button class="btnBelanja" @click="$bvModal.show('modal-store')"
						><b-icon-cart3></b-icon-cart3> Belanja Stella</b-button
					>
					<router-link class="nav-link" to="/tips">Tips</router-link>
					<a href="#" class="nav-link" @click="$bvModal.show('modal-subs-nav')">
						Langganan</a
					>
					<b-nav-item-dropdown
						v-if="userLogin"
						split
						split-href="#foo/bar"
						right
						:class="'profile-name-login'"
					>
						<!-- Using 'button-content' slot -->
						<template #button-content>
							{{ userLogin }}
						</template>
						<b-dropdown-item-button @click="profileClick"
							>Profile</b-dropdown-item-button
						>
						<b-dropdown-item-button @click="logoutClick"
							>Logout</b-dropdown-item-button
						>
					</b-nav-item-dropdown>

					<router-link v-else class="nav-link" to="/login">Masuk</router-link>
					<b-nav-item-dropdown text="Bahasa">
						<b-dropdown-item href="#Weglot-id">Indonesia</b-dropdown-item>
						<b-dropdown-item href="#Weglot-en">English</b-dropdown-item>
					</b-nav-item-dropdown>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>

		<b-modal size="lg" ref="blnjaModal" id="modal-store" centered hide-header hide-footer>
			<div class="d-block text-center store-belanja row">
				<div class="col-12 col-lg-12">
					<b-button
						style="position: absolute;right:5%"
						class="close mdl-blnj"
						@click="hideModalbelanja"
						>x</b-button
					>
					<h3 class="bold-stella">SHOP</h3>
					<p>OFFICIAL STORE</p>
				</div>
				<div class="col-12 col-lg-12">
					<div class="container">
						<div class="row">
							<div
								v-for="newSO in storeoficialNew"
								:key="newSO.id_ecommerce"
								class="col-3 col-lg-3 text-center"
							>
								<a
									class="navbar-brand tengah-store"
									:href="'//' + newSO.link_ecommerce_official"
								>
									<img :src="newSO.link_logo_official" width="100%" />
								</a>
								<p v-if="newSO.updiscount != 0">{{ newSO.nama_ecommerce }} {{ newSO.updiscount }}% </p>
								<p v-else>{{ newSO.nama_ecommerce }} </p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal
			id="modal-subs-nav"
			ref="modal-nav-sub"
			centered
			hide-header
			hide-footer
		>
			<div class="d-block text-center store-belanja">
				<h3 class="bold-stella">Langganan</h3>
				<div
					style="margin-top: 15%;margin-bottom: 15%"
					class="loading text-center mt-10"
					:class="{ hilang: afterSubmit }"
				>
					<img src="../assets/images/testing/load.gif" width="5%" />
				</div>
				<div :class="{ hilang: beforeSubmit }" class="container formBox">
					<form class="mt-3  formSubs" @submit="postSubscribe" method="post">
						<div class="row">
							<div class="col-6 col-lg-6">
								<div class="plc-sub">Nama</div>
								<div class="inputBox ">
									<input
										type="text"
										name="nama"
										class="input"
										v-model="posts.nama"
									/>
								</div>
							</div>

							<div class="col-6 col-lg-6">
								<div class="plc-sub">Email</div>
								<div class="inputBox">
									<input
										type="text"
										name="email"
										class="input"
										v-model="posts.email"
									/>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-6 col-lg-6">
								<div class="plc-sub">Jenis Kelamin</div>
								<div class="inputBox">
									<select v-model="posts.gender" class="input">
										<option :value="null" disabled selected>gender</option>
										<option>Laki-laki</option>
										<option>Perempuan</option>
									</select>
								</div>
							</div>

							<div class="col-6 col-lg-6">
								<div class="plc-sub">No. Handphone</div>
								<div class="inputBox">
									<input
										type="text"
										name="phone"
										class="input"
										v-model="posts.phone"
									/>
								</div>
							</div>
						</div>

						<div class="text-center">
							<button type="submit" class="btnSubs">SUBMIT</button>
						</div>
					</form>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
export default {
	name: "Navbar",
	data() {
		return {
			userLogin: null,
			storeoficialNew: [],
			posts: {
				nama: null,
				email: null,
				gender: null,
				phone: null,
			},
			afterSubmit: true,
			beforeSubmit: false,
			mobileView: false,
		};
	},
	methods: {
		hideModalbelanja() {
			this.$refs["blnjaModal"].hide();
		},
		setStoreOfficial(dataofficial) {
			this.storeoficialNew = dataofficial;
		},
		postSubscribe(data) {
			this.hideModal(data);
			this.afterSubmit = false;
			this.beforeSubmit = true;
			HTTP.post("subscribe", this.posts)
				// .then((response) => this.setArtikelHome(response.data))
				// jika sukses
				.then((response) => {
					console.log(response.status);
					// this.afterSubs = false;
					// this.beforeSubs = true;
					this.$toast.success("Anda berhasil Subscribe. terimakasih.", {
						type: "success",
						position: "top-right",
						duration: 3000,
						dismissible: true,
					});

					this.hideModal();
					this.afterSubmit = true;
					this.beforeSubmit = false;
				})
				// jika gagal
				.catch((error) => {
					console.log(error.response.status);

					this.$toast.error("Anda gagal Subscribe. silahkan ulangi.", {
						type: "error",
						position: "top-right",
						duration: 3000,
						dismissible: true,
					});
					this.afterSubmit = true;
					this.beforeSubmit = false;
				});
			// this.subdata = data;
			// console.warn(this.posts)
			data.preventDefault();

			// this.afterSubs = false;
		},
		hideModal() {
			this.$refs["modal-nav-sub"].hide();

			// console.log("test");
			// data.preventDefault();
		},
		resetform() {
			this.nama = "";
			this.email = "";
			this.gender = "";
			this.phone = "";
		},
		logoutClick() {
			localStorage.removeItem("token");
			localStorage.removeItem("nama");
			// this.$router.push("/login");
			window.location.href = "/login";
		},

		profileClick() {
			this.$router.push("/profile");
		},
		handleViewMobile() {
			this.mobileView = window.innerWidth <= 990;
			console.log("test");
		},
	},
	async created() {
		HTTP.get("official_eccommerce")
			.then((response) => this.setStoreOfficial(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
		if (localStorage.getItem("token") != null) {
			HTTP.get("profile", {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			})
				.then((response) => {
					console.log(response.status);
					this.userLogin = localStorage.getItem("nama");
				})
				.catch((error) => console.log(error));
		} else {
			console.log("belum login");
		}
	},
};
</script>

<style></style>
