<template>
  <div class="container mt-5">
			<h3 class="bold-stella jdl-prod" style="color:black">Privacy Policy</h3>
			<div class="privacy-policy">
				
				<h3 class="jdl-prod bold-stella">INFORMASI PENGGUNA</h3>
				<p align="justify">
					Semua informasi yang Perusahaan peroleh dari Anda adalah informasi
					yang Anda berikan secara sukarela antara lain sewaktu Anda membuka
					akun untuk dapat menggunakan dan mengakses Layanan, dan juga sewaktu
					Pengguna mengunjungi halaman situs para mitra tertentu dari
					Perusahaan. Informasi tersebut dapat berupa namun tidak terbatas pada
					data pribadi Anda seperti nama, alamat Anda, nomor telepon Anda,
					alamat&nbsp;e-mail&nbsp;Anda, juga informasi&nbsp;log&nbsp;yang
					tersedia di server kami, alamat protokol internet, informasi aktivitas
					perangkat, jenis peramban, tanggal dan waktu permintaan,
					serta&nbsp;URL&nbsp;perunjuk Anda. Data Anda digunakan dan dilindungi
					oleh Perusahaan sesuai dengan peraturan perundang-undangan di bidang
					perlindungan data dan kebijakan privasi ini. Perusahaan mengumpulkan
					informasi Pengguna dengan tujuan antara lain;
				</p>
				<ul>
					<li>Memverifikasi data Pengguna.</li>
					<li>
						Memfasilitasi komunikasi antara perusahaan dan pengguna Memberikan
						pengalaman pengguna (user interface) Layanan yang lebih baik.
					</li>
					<li>Kepentingan konfirmasi dan pengiriman.</li>
					<li>Mengumpulkan data transaksi pengguna Layanan.</li>
					<li>
						Untuk kepentingan riset pasar atau pengembangan Layanan agar
						Perusahaan dapat meningkatkan pelayanan, dan/atau untuk menyesuaikan
						situs untuk memberikan layanan dan produk yang paling diminati oleh
						Anda.
					</li>
					<li>
						Untuk mengelola sebuah kontes, promosi, survei, riset pasar, diskusi
						kelompok fokus atau lainnya dan untuk menyediakan Pengguna dengan
						produk atau layanan yang relevan (contoh: untuk mengirimkan hadiah
						kepada Anda apabila Anda telah menang dalam kontes yang
						diselenggarakan oleh Perusahaan); dan
					</li>
					<li>
						Memfasilitasi komunikasi antara Pengguna dan bagian pelayanan
						konsumen Perusahaan apabila ada permasalahan.
					</li>
				</ul>
				<p align="justify">
					Sewaktu Anda memutuskan melakukan pembukaan akun untuk dapat
					menggunakan dan mengakses Layanan maka Perusahaan akan mengumpulkan
					informasi tertentu dan juga data pribadi Anda, antara lain berupa
					namun tidak terbatas pada nama lengkap, alamat&nbsp;e-mail, tempat dan
					tanggal lahir, nomor telepon yang dapat dihubungi, kata sandi dan
					informasi lainnya yang diperlukan. Data-data tersebut juga harus Anda
					perbaharui atau perbaiki apabila memang ada perubahan atau kesalahan
					guna memastikan kesinambungan dan keamanan Layanan kami. Anda wajib
					menyediakan informasi sebagaimana diminta oleh Perusahaan. Jika
					menurut Perusahaan Anda tidak menyediakan informasi secara memadai,
					maka Perusahaan tidak dapat menyelesaikan proses pembukaan akun Anda
					atau Anda tidak dapat lagi untuk menggunakan atau mengakses Layanan.
				</p>

				<h3 class="jdl-prod bold-stella">PENGUNGKAPAN INFORMASI PENGGUNA</h3>
				<p align="justify">
					Perusahaan tidak akan melakukan mengalihkan, menjual,
					mendistribusikan, mengungkapkan atau meminjamkan informasi dan juga
					data pribadi Anda kepada pihak ketiga lain, tanpa terdapat izin dari
					Anda terkecuali dalam hal-hal sebagai berikut:
				</p>
				<ul>
					<li>
						Apabila Perusahaan secara keseluruhan atau sebagian besar asetnya
						diakuisisi atau merger dengan pihak ketiga, maka informasi dan data
						pribadi Anda yang disimpan oleh Perusahaan, secara otomatis akan
						menjadi salah satu aset yang dialihkan atau digabung
					</li>
					<li>
						Apabila Perusahaan mengungkapkan infromasi tersebut kepada suatu
						pihak yang telah menandatangani perjanjian kerahasiaan dengan
						Perusahaan
					</li>
					<li>
						Apabila Perusahaan berkewajiban mengungkapkan dan/atau berbagi data
						pribadi Anda dalam upaya mematuhi kewajiban hukum dan/atau dalam
						upaya memberlakukan atau menerapkan Persyaratan Layanan, atau untuk
						melindungi hak, properti, atau keselamatan Perusahaan, pelanggan
						Perusahaan, atau pihak lain. Di sini termasuk pertukaran informasi
						dengan perusahaan dan organisasi lain untuk tujuan perlindungan
						Perusahaan beserta penggunanya termasuk namun tidak terbatas pada
						permasalahan penipuan, kerugian finansial atau pengurangan resiko
						lainnya; dan
					</li>
					<li>
						Untuk hal-hal atau tujuan-tujuan sebagaimana dijelaskan pada bagian
						nomor 2 di atas.
					</li>
				</ul>
				<p align="justify">
					Kami dapat membagikan informasi non-pribadi yang dapat teridentifikasi
					kepada publik dan mitra kami – seperti penayang, pengiklan, atau situs
					yang tersambung. Misalnya, kami dapat membagikan informasi yang
					tersedia secara publik untuk menunjukkan tren mengenai penggunaan umum
					atas layanan kami.
				</p>

				<h3 class="jdl-prod bold-stella">HAK UNTUK MENGAKSES DAN MERUBAH DATA</h3>
				<p align="justify">
					Kami juga telah mempersiapkan&nbsp;database&nbsp;yang memungkinkan
					pengguna yang melakukan pembelian dan penjualan melalui Layanan untuk
					menyimpan informasi mengenai penagihan, pengiriman barang, pembayaran,
					melacak status dari pesanan pengguna, informasi terkait kartu kredit,
					daftar transaksi sebelumnya, alamat&nbsp;e-mail&nbsp;dan beberapa data
					pribadi lainnya. Anda memiliki hak untuk mengakses informasi dan untuk
					meminta koreksi atas informasi tersebut. Jika Anda ingin mengakses
					atau memperbaiki data pribadi Anda, Anda dapat melakukannya dengan
					login lalu mengakses ‘Edit Profile’ yang terdapat pada situs atau
					aplikasi Stella Air Freshener.
				</p>

				<h3 class="jdl-prod bold-stella">COOKIES</h3>
				<p align="justify">
					Perusahaan dapat menggunakan berbagai macam teknologi untuk
					mengumpulkan dan menyimpan informasi saat Anda menggunakan dan
					mengakses Layanan, yang mana teknologi tersebut dapat mencakup
					penggunaan&nbsp;<i>cookies</i>&nbsp;&nbsp;atau teknologi
					serupa.&nbsp;<i>Cookies</i>&nbsp;adalah file kecil yang secara
					otomatis akan mengambil tempat di dalam perangkat komputer Anda untuk
					mengindentifikasi dan memantau koneksi jaringan Anda, sehingga
					memungkinkan Anda untuk menggunakan dan mengakses Layanan secara
					optimal.&nbsp;Cookies&nbsp;juga secara otomatis akan membantu sistem
					kami untuk mengetahui browser apa yang Anda gunakan dan dapat
					memberikan Anda layanan tambahan seperti misalnya penyimpanan data
					mengenai apa saja yang telah Anda beli melalui Layanan kami.
				</p>

				<h3 class="jdl-prod bold-stella">KEAMANAN INFORMASI</h3>
				<p align="justify">
					Kami bekerja keras untuk melindungi data pribadi Anda dari akses tanpa
					izin atau pengubahan, pengungkapan, maupun penghancuran tanpa izin.
					Pada khususnya:
				</p>
				<ul>
					<li>
						Kami mengenkripsi sejumlah layanan menggunakan&nbsp;<i
							>Secure Socket Layers</i
						>&nbsp;(“<b>SSL</b>”).
					</li>
					<li>
						Kami meninjau praktik-praktik seputar pengumpulan, penyimpanan, dan
						pemrosesan informasi, termasuk langkah keamanan teknis dan fisik,
						untuk melindungi sistem dari akses tak sah.
					</li>
					<li>
						Kami membatasi akses ke informasi pribadi hanya kepada karyawan
						kami, kontraktor, dan agen yang perlu mengetahui informasi tersebut
						untuk memprosesnya bagi kami, atau yang tunduk pada kewajiban
						kerahasiaan kontrak yang tegas, serta dapat dikenakan sanksi atau
						dihentikan jika gagal memenuhi kewajiban ini.
					</li>
				</ul>
				<p align="justify">
					Apabila tidak didefinisikan secara khusus di dalam kebijakan ini,
					istilah-istilah yang didefinisikan dan digunakan di dalam Syarat dan
					Ketentuan Umum Penggunaan dan Akses, Syarat dan Ketentuan Penggunaan
					dan Akses Stella Air Freshener, Pedoman Isi Konten Pada Stella Air
					Freshener, Syarat dan Ketentuan Penggunaan dan Akses Stella Air
					Freshener, dan/atau kebijakan lainnya sebagaimana terdapat di dalam
					atau terkait dengan Layanan.
				</p>
			</div>
		</div>
</template>

<script>

export default {
	name: "ProvacyPolicy",
	methods:{
		
		scroollToTop(){
			window.scrollTo(0,0)
		}
	},
	created(){
		this.scroollToTop();
	}
};
</script>

<style>

</style>