<template>
	<div style="padding:2%" class="container-fluid mix-produk">
		<div class="container-fluid">
			<div
				style="margin-top: 15%;margin-bottom: 15%"
				class="loading text-center mt-10"
				:class="{ hilang: loading }"
			>
				<img src="../assets/images/testing/load.gif" width="5%" />
			</div>
			<div :class="{ hilang: afterLoading }" class="row text-center h-100">
				<div class="col-6 col-lg-3 text-center">
					<div>
						<img
							src="../assets/images/testing/asset_super sale.png"
							width="80%"
						/>
					</div>
				</div>
				<div
					v-for="mixProduk in mixProduks"
					:key="mixProduk.id_produk"
					class="col-lg-3 second-text mix-webprod"
				>
					<router-link :to="'/produk-detail/' + mixProduk.slug">
						<div class="produk-card">
							<div v-if="mixProduk.foto_landscape == 1" class="mp mt-auto">
								<img :src="mixProduk.url_foto_produk" />
							</div>
							<div v-else-if="mixProduk.foto_landscape == 2" class="ms mt-auto">
								<img :src="mixProduk.url_foto_produk" />
							</div>
							<div v-else-if="mixProduk.foto_landscape == 3" class="ml mt-auto">
								<img :src="mixProduk.url_foto_produk" />
							</div>
							<div>
								<p class="text-produk-mix">{{ mixProduk.nama_produk }}</p>
							</div>
						</div>
					</router-link>
				</div>
				<div class="col">
				<b-carousel
					id="carousel-fade1"
					class="mixmobile-prod"
					:interval="500000"
					controls
					indicators
					style="text-shadow: 1px 1px 2px #333;"
				>
					<div v-for="mixProduk in mixProduks" :key="mixProduk.id_produk">
						<router-link :to="'/produk-detail/' + mixProduk.slug">
						<b-carousel-slide :img-src="mixProduk.url_foto_produk" :to="'/produk-detail/' + mixProduk.slug" >
						</b-carousel-slide>
						</router-link>
					</div>
				</b-carousel>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
export default {
	name: "MixProdukHome",
	data() {
		return {
			mixProduks: [],
			loading: false,
			afterLoading: true,
		};
	},
	methods: {
		setProdukMix(data) {
			this.mixProduks = data;
			this.loading = true;
			this.afterLoading = false;
		},
	},
	mounted() {
		HTTP.get("produk_promoted")
			.then((response) => this.setProdukMix(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
	},
};
</script>

<style></style>
