<template>
	<div class="fluid">
		<div
			style="margin-top: 15%;margin-bottom: 15%"
			class="loading text-center mt-10"
			:class="{ hilang: loading }"
		>
			<img src="../assets/images/testing/load.gif" width="5%" />
		</div>
		<div class="container-fluid" :class="{ hilang: beforeSubs }">
			<H2 class="text-center bold-stella jdl-prod">Berlangganan</H2>
			<p class="text-center ph-prod">
				Dapatkan berbagai penawaran menarik serta informasi terbaru mengenai
				<br />
				produk Stella hanya dengan berlangganan newsletter kami
			</p>
			<div class="container formBox">
				<form class="mt-3" @submit="postSubscribe" method="post">
					<div class="row">
						<div class="col-sm-6">
							<div class="inputBox ">
								<div class="inputText">Nama</div>
								<input
									type="text"
									name="nama"
									class="input"
									v-model="posts.nama"
								/>
							</div>
						</div>

						<div class="col-sm-6">
							<div class="inputBox">
								<div class="inputText">Email</div>
								<input
									type="text"
									name="email"
									class="input"
									v-model="posts.email"
								/>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6">
							<div class="inputBox">
								
								<div class="inputText">Jenis Kelamin</div>
								
								<select v-model="posts.gender" class="input" >
									<option>Laki-laki</option>
									<option>Perempuan</option>
								</select>
							</div>
						</div>

						<div class="col-sm-6">
							<div class="inputBox">
								<div class="inputText">Nomor Telepon</div>
								<input
									type="text"
									name="phone"
									class="input"
									v-model="posts.phone"
								/>
							</div>
						</div>
					</div>

					<div class="text-center">
						<button type="submit" class="btnSubs">KIRIM</button>
					</div>
				</form>
			</div>
		</div>
		<div :class="{ hilang: afterSubs }" class="container-fluid">
			<H2 class="text-center bold-stella">Berlangganan</H2>
			<p class="text-center">
				Anda Berhasil Berlangganan
			</p>
		</div>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
export default {
	name: "Subscribe",
	data() {
		return {
			posts: {
				nama: null,
				email: null,
				gender: null,
				phone: null,
			},
			loading: true,
			afterSubs: true,
			beforeSubs: false,
		};
	},
	methods: {
		postSubscribe(data) {
			(this.loading = false), (this.beforeSubs = true);
			HTTP.post("subscribe", this.posts)
				// .then((response) => this.setArtikelHome(response.data))
				// jika sukses
				.then((response) => {
					console.log(response.status);
					this.afterSubs = false;
					this.beforeSubs = true;
					this.loading = true;
				})
				// jika gagal
				.catch((error) => {
					console.log(error.response.status);
					this.afterSubs = true;
					this.beforeSubs = false;
					this.loading = true;
					this.$toast.error("Anda gagal Subscribe. silahkan ulangi.", {
						type: "error",
						position: "top-right",
						duration: 3000,
						dismissible: true,
					});
				});
			// this.subdata = data;
			// console.warn(this.posts)
			data.preventDefault();

			// this.afterSubs = false;
		},
	},
	created(){
		// if (localStorage.getItem("token") != null) {
		// 	this.afterSubs = false;
		// 	this.beforeSubs = true;
		// 	this.loading = true;
		// } else {
		// 	console.log("-");
		// }
	},
};
</script>

<style></style>
