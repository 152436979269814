<template>
    <div class="content">
        <div> 
            <pre>{{ xmlContent }}</pre>
        </div>
  </div>
</template>
<script>
// import Voucher from "@/components/Voucher.vue"

import { HTTP } from "../config/http-common.js";
// import XmlViewer from 'vue-xml-viewer'

export default {
	name: "PageSitemaps",
	
	// components: {
	// 	// Sitemaps
	// 	// XmlViewer
	// },
	data() {
		return {
			xmlContent: null,
		};
	},
	methods: {
		getData() {
			this.xmlContent = null;
			// this.scroollToTop();
			HTTP.get("sitemap.xml/artikel")
				.then((response) => {
					// this.xmlArtikel= response.data	;
					this.xmlContent = response.data;
					// console.log(response.data);
				})
				.catch((error) => {
					console.log(error);
					// localStorage.removeItem("token");
					// localStorage.removeItem("nama");
					// this.$router.push("/");
				});
		},
	},
	created() {
		this.getData();
	}
};
</script>

<style></style>
