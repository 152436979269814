<template>
  <div class="produk">
    <AllProduk />
    
  </div>
</template>

<script>
import AllProduk from '@/components/AllProduk.vue';

export default {
  name: 'Produk',
  components: {
    AllProduk
  }
}

</script>

<style>
 
</style>