<template>
	<div class="container mt-2">
		<div class="row h-100 vw">
			<div class="prof-vw">
				<router-link to="/voucher" tag="button" class="mr-1 mr-lg-3"
					>Voucher</router-link
				>
				<router-link to="/wishlist" tag="button" class="">Wishlist</router-link>
			</div>
		</div>
		<div class="row h-100 justify-content-center align-items-center">
			<div class="profile_pic ">
				<!-- <img
					src="https://bootsnipp.com/img/avatars/92ff0456eb6106d0c1ae8ab5f1fe0afe2d2d25f2.jpg"
				/> -->
				<b-icon
					icon="person-circle"
					style="width: 120px; height: 120px;"
				></b-icon
				>
			</div>
		</div>
		<div class="row h-100 justify-content-center align-items-center mt-1">
			<div class="star-profile">
				<b-icon icon="star-fill" class="h2 mb-2"></b-icon>
				<b-icon icon="star-fill" class="h2 mb-2"></b-icon>
				<b-icon icon="star-fill" class="h2 mb-2"></b-icon>
				<b-icon icon="star-fill" class="h2 mb-2"></b-icon>
				<b-icon icon="star" class="h2 mb-2"></b-icon>

				<!-- <router-link to="/riwayat" tag="button" class="prof-more"
					>lihat lebih</router-link
				> -->
				<button v-on:click="klikbtn" tag="button" class="prof-more">Edit Profile</button>
			</div>
		</div>
		<div class="container col-10 col-lg-10">
			<form :class="{ hilang: profileForm }" id="sprofile" class="mt-5 mb-5 formSubs">
				<div class="row">
					<div class="col-12 col-lg-12">
						<div class="inputBox inProf">
							<div class="form-group row">
								<label class="col-3 col-lg-4 formprof ">Nama</label>
								<div class="col-9 col-lg-8">
									<p class="data-prof">{{ detailUser.name }}</p>
									<!-- <input placeholder="nama" type="text" :value="detailUser.name" class="data-prof input" readonly /> -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-lg-12">
						<div class="inputBox inProf">
							<div class="form-group row">
								<label class="col-3 col-lg-4 formprof">Email</label>
								<div class="col-9 col-lg-8">
									<p class="data-prof">{{ detailUser.email }}</p>
									<!-- <input placeholder="nama" type="text" :value="'nama'" class="input" readonly /> -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-lg-12">
						<div class="inputBox inProf">
							<div class="form-group row">
								<label class="col-3 col-lg-4 formprof">Jenis Kelamin</label>
								<div class="col-9 col-lg-8">
									<p class="data-prof">{{ detailUser.gender }}</p>
									<!-- <input placeholder="nama" type="text" :value="'nama'" class="input" readonly /> -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-lg-12">
						<div class="inputBox inProf">
							<div class="form-group row">
								<label class="col-3 col-lg-4 formprof">No Telp</label>
								<div class="col-9 col-lg-8">
									<p class="data-prof">{{ detailUser.nohp }}</p>
									<!-- <input placeholder="nama" type="text" :value="'nama'" class="input" readonly /> -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-lg-12">
						<div class="inputBox inProf">
							<div class="form-group row">
								<label class="col-3 col-lg-4 formprof">Alamat</label>
								<div class="col-9 col-lg-8">
									<textarea
										type="text"
										name="message"
										rows="3"
										class="form-control md-textarea data-prof"
										readonly
										:value="detailUser.alamat"
									>
									</textarea>
									<!-- <input placeholder="nama" type="text" :value="'nama'" class="input" readonly /> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
			<form :class="{ hilang: editForm }"  id="eprofile" class="mt-5 mb-5 formSubs" @submit="saveEditBtn">
				<h4 class="text-center">Edit Profile</h4>
				<div class="row">
					<div class="col-12 col-lg-12">
						<div class="inputBox inProf">
							<div class="form-group row">
								<label class="col-3 col-lg-4 formprof ">Nama</label>
								<div class="col-9 col-lg-8">
									<input
										placeholder="nama"
										id="namaProfile"
										type="text"
										:value="detailUser.name"
										class="data-prof input"
										required
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-lg-12">
						<div class="inputBox inProf">
							<div class="form-group row">
								<label class="col-3 col-lg-4 formprof">Email</label>
								<div class="col-9 col-lg-8">
									<input
										placeholder="email"
										type="text"
										:value="detailUser.email"
										class="data-prof input"
										readonly
										required
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-lg-12">
						<div class="inputBox inProf">
							<div class="form-group row">
								<label class="col-3 col-lg-4 formprof">Jenis Kelamin</label>
								<div
									v-if="detailUser.gender == 'Laki - laki'"
									class="col-9 col-lg-8"
								>
									<select class="input data-prof" id="genderProfile">
										<option selected>Laki - laki</option>
										<option>Perempuan</option>
									</select>
								</div>
								<div
									v-else-if="detailUser.gender == 'Perempuan'"
									class="col-9 col-lg-8"
								>
									<select class="input data-prof" id="genderProfile">
										<option>Laki - laki</option>
										<option selected>Perempuan</option>
									</select>
								</div>
								<div v-else class="col-9 col-lg-8">
									<select class="input data-prof" id="genderProfile">
										<option>Laki - laki</option>
										<option>Perempuan</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-lg-12">
						<div class="inputBox inProf">
							<div class="form-group row">
								<label class="col-3 col-lg-4 formprof">No Telp</label>
								<div class="col-9 col-lg-8">
									<input
										placeholder="No Telp"
										id="phoneProfile"
										type="text"
										:value="detailUser.nohp"
										class="data-prof input"
										required
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-lg-12">
						<div class="inputBox inProf">
							<div class="form-group row">
								<label class="col-3 col-lg-4 formprof">Alamat</label>
								<div class="col-9 col-lg-8">
									<textarea
										placeholder="Alamat "
										type="text"
										name="message"
										rows="3"
										class="data-prof input"
										:value="detailUser.alamat"
										id="alamatProfile"
										required
									>
									</textarea>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="text-center">
					<button type="submit" class="btnSubs text-center">Simpan</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
export default {
	name: "Profile",
	data() {
		return {
			detailUser: {},
			profileForm: false,
			editForm: true,
		};
	},
	methods: {
		setUserDetail(data) {
			this.detailUser = data;
			// this.loading = true;
			// this.afterLoading = false;
			this.profileForm = false;
			this.editForm = true;
			
		},
		klikbtn(){
			
			this.profileForm = true;
			this.editForm = false;
		},
		scroollToTop() {
			window.scrollTo(0, 0);
		},
		getData(){
			this.scroollToTop();
			HTTP.get("profile", {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			})
				.then((response) => {
					this.setUserDetail(response.data);
					// console.log(response.data);
					this.userLogin = localStorage.getItem("nama");
				})
				.catch((error) => {
					console.log(error);
					localStorage.removeItem("token");
					localStorage.removeItem("nama");
					this.$router.push("/");
				});
		},
		editBtn() {
			var form = document.getElementById("sprofile");
			form.classList.add("hilang");
			var forme = document.getElementById("eprofile");
			forme.classList.remove("hilang");
		},
		saveEditBtn(data) {
			var namaProfile = document.getElementById("namaProfile").value;
			var genderProfile = document.getElementById("genderProfile").value;
			var phoneProfile = document.getElementById("phoneProfile").value;
			var alamatProfile = document.getElementById("alamatProfile").value;
			const updateUserProfile = {
				name: namaProfile,
				gender: genderProfile,
				nohp: phoneProfile,
				alamat: alamatProfile,
			};
			
			// console.log(dataKomentar);
			HTTP.post("/updateprofile", updateUserProfile, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			})
				// jika sukses
				.then((response) => {
					console.log(response.status);
					this.$toast.success("Anda berhasil Update Profile. terimakasih.", {
						type: "success",
						position: "top-right",
						duration: 3000,
						dismissible: true,
					});
					this.getData();
				})
				// jika gagal
				.catch((error) => {
					console.log(error.response.headers);

					this.$toast.error("Anda gagal Update Profile. silahkan ulangi.", {
						type: "error",
						position: "top-right",
						duration: 3000,
						dismissible: true,
					});
				});
			data.preventDefault();
		},
	},
	created() {
		this.getData();
	},
};
</script>

<style></style>
