<template>
	<footer class="stella-second">
		<hr />
		<div class="container mobile-txt-footer">
			<div class="row mt-3">
				<div class="col-lg col-12 text-center">
					<a class="navbar-brand" href="/"
						><img src="../assets/images/logo-stella.png" width="100%"
					/></a>
				</div>
				<div class="col-lg col-6">
					<div class="col text-left bold-stella">Tautan Lainnya</div>

					<router-link class="nav-link footer-text" :to="'/contact'"
						>Hubungi Kami</router-link
					>
					<router-link class="nav-link footer-text" to="/produk"
						>Produk</router-link
					>

					<router-link class="nav-link footer-text" to="/privacy-policy"
						>Kebijakan Kami</router-link
					>
				</div>
				<div class="col-lg col-6">
					<div class="col text-left bold-stella">Layanan Pelanggan</div>
					<a class="nav-link footer-support" :href="`tel:089012345`"
						><b-icon-telephone-fill class="call-footer"></b-icon-telephone-fill
						>(+621) 89012345</a
					>
					<a class="nav-link footer-support" :href="`mailto:contact@stella-airfreshener`"
						><b-icon-chat-left-dots-fill
							class="call-footer"
						></b-icon-chat-left-dots-fill
						>contact@stella-airfreshener</a
					>
				</div>
				<div class="col-lg">
					<div class="col text-left bold-stella">Lokasi</div>
					<p class=" nav-link footer-support text-left">
						Godrej Indonesia <br />Secure Building A, 2nd-3rd Floor, Jl Raya<br />
						Protokol Halim Perdanakusuma<br />RT.5/RW.10, Halim
						Perdanakusuma,<br />
						Makassar, East Jakarta City, Jakarta 13610
					</p>
				</div>
			</div>
		</div>
		<div class="footer-copyright text-center py-3 stella-second">
			Copyright &copy;2021 Godrej Indonesia
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer",
};
</script>

<style></style>
