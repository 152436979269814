<template>
  <div class="login">
    <LoginPage />
    
  </div>
</template>

<script>
import LoginPage from '@/components/LoginPage.vue';

export default {
  name: 'Produk',
  components: {
    LoginPage
  },
  // created(){
  //   const descEl = document.querySelector('head meta[name="description"]');

  //   descEl.setAttribute('content', "Login Stella");
  // }
}

</script>

<style>
 
</style>