<template>
  <div class="mistdiffuser">
    <iframe width="1280" height="720" src="https://www.youtube.com/embed/-taTDpQg4sQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: "Mistdiffuser",
  components: {
  },
  data() {
    return {
    };
  },
}
</script>

<style>
.mistdiffuser {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
@media only screen and (max-width: 768px) {
  .mistdiffuser {
    margin: 1rem;
  }
}
</style>