<template>
	<div class="fluid">
		<div class="container all-produk">
			<h5 class="bold-stella m-1">Update</h5>
			<div class="input-group mb-3">
				<input
					v-model="updateSearch"
					type="text"
					class="form-control txt-search"
					placeholder="Cari Update"
					aria-label="Cari Update"
					aria-describedby="basic-addon1"
				/>
				<span class="input-group-text logo-search"
					><b-icon-search></b-icon-search
				></span>
			</div>

			<div class="row">
				<div class="col-12 mb-2 web-view">
					<b-button class="btnFilter" v-b-modal.modal-xl variant="outline-info"
						><b-icon icon="sliders"></b-icon> Filter</b-button
					>
				</div>
				<div class="col-lg-2 mobile-view">
					<p class="sub-judul m-1">Filter</p>

					<p v-b-toggle.collapse-3 class="sub-judul-1 m-1">Format <b-icon icon="caret-down-fill"></b-icon></p>
					<b-collapse
						v-for="newFT in formatTipeNew"
						:key="newFT.id_format_tipe"
						visible
						id="collapse-3"
						class="clp-filter"
					>
						<div class="chiller_cb">
							<input
								:id="newFT.id_format_tipe"
								type="checkbox"
								:value="newFT.id_format_tipe"
								v-model="tFormat"
								v-on:checked="filterArtikelUpdate"
							/>
							<label :for="newFT.id_format_tipe">{{
								newFT.nama_format_tipe
							}}</label>
							<span></span>
						</div>
					</b-collapse>
				</div>
				<div class="col-12 col-lg-10 tab-prod">
					<b-tabs content-class="mt-3">
						<b-tab title="Acara" active>
							<div class="fluid mb-3">
								<div class="row text-center h-100">
									<div
										v-for="newEvent in filterArtikelUpdate"
										:key="newEvent.id_artikel"
										:class="{ hilang: afterLoading }"
										class="col-12 col-lg-4 text-center"
									>
										<router-link
											class="card shadow mb-4"
											:to="'/artikel/' + newEvent.slug"
										>
											<div class="update-card ">
												<img :src="newEvent.url_gambar_artikel" width="100%" />
												<div class="top-left" style="color: #3dae2b;">Free</div>
											</div>
											<div class="row mt-3">
												<div class="pbls col-4 col-lg-4">
													<p>
														{{ newEvent.bulan_publish }}<br />

														{{ newEvent.tahun_publish }}
													</p>
												</div>
												<div class="col-8 col-lg-8 text-left desc-up" align="justify">
													<p class="bold-stella jdl-prod">
														{{ newEvent.judul_artikel }}
													</p>
													<p class="mini-desc des-artikel" align="justify">
														{{ newEvent.meta_desc }}
													</p>
												</div>
											</div>
										</router-link>
									</div>
								</div>

								<div :class="{ hilang: loading }" class="col-lg-12 text-center">
									<div class="">
										<img
											class="card-img"
											src="../assets/images/testing/load.gif"
											style="width: 10%;!important"
										/>
									</div>
								</div>
							</div>
						</b-tab>
						<b-tab title="Promosi">
							<div class="fluid mb-3">
								<div class="row text-center h-100">
									<div
										v-for="newPromo in filterPromoUpdate"
										:key="newPromo.id_artikel"
										:class="{ hilang: afterLoadingprom }"
										class="col-12 col-lg-4 text-center"
									>
										<router-link
											class="card shadow mb-4"
											:to="'/artikel/' + newPromo.slug"
										>
											<div class="update-card ">
												<img :src="newPromo.url_gambar_artikel" width="100%" />
											</div>
											<div class="row mt-3">
												<div class="pbls col-4 col-lg-4">
													<p>
														{{ newPromo.bulan_publish }}<br />

														{{ newPromo.tahun_publish }}
													</p>
												</div>
												<div class="col-8 col-lg-8 text-left desc-up">
													<p class="bold-stella jdl-prod" align="justify">
														{{ newPromo.judul_artikel }}
													</p>
													<p class="mini-desc des-artikel" align="justify">
														{{ newPromo.meta_desc }}
													</p>
												</div>
											</div>
										</router-link>
									</div>
								</div>

								<div
									:class="{ hilang: loadingprom }"
									class="col-lg-12 text-center"
								>
									<div class="">
										<img
											class="card-img"
											src="../assets/images/testing/load.gif"
											style="width: 10%;!important"
										/>
									</div>
								</div></div
						></b-tab>
					</b-tabs>
				</div>
			</div>
		</div>

		<b-modal
			content-class="filterMobile"
			id="modal-xl"
			size="xl"
			title="Extra Large Modal"
			hide-header
			hide-footer
		>
			<div class="col-lg-12">
				<p class="sub-judul m-1">Filter</p>

				<p v-b-toggle.collapse-3 class="sub-judul-1">Format <b-icon icon="caret-down-fill"></b-icon></p>
				<b-collapse
					v-for="newFT in formatTipeNew"
					:key="newFT.id_format_tipe"
					visible
					id="collapse-3"
					class="clp-filter"
				>
					<div class="chiller_cb">
						<input
							:id="newFT.id_format_tipe"
							type="checkbox"
							:value="newFT.id_format_tipe"
							v-model="tFormat"
							v-on:checked="filterArtikelUpdate"
						/>
						<label :for="newFT.id_format_tipe">{{
							newFT.nama_format_tipe
						}}</label>
						<span></span>
					</div>
				</b-collapse>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
export default {
	name: "UpdateContent",
	data() {
		return {
			eventNew: [],
			loading: false,
			afterLoading: true,
			promoNew: [],
			loadingprom: false,
			afterLoadingprom: true,
			formatTipeNew: [],
			updateSearch: "",
			tFormat: [],
		};
	},
	methods: {
		setFormatType(dataFormat) {
			this.formatTipeNew = dataFormat;
		},
		setEventContent(data) {
			this.eventNew = data;
			this.loading = true;
			this.afterLoading = false;
		},
		setPromoContent(datapromo) {
			this.promoNew = datapromo;
			this.loadingprom = true;
			this.afterLoadingprom = false;
		},
		// searchUpdate() {

		// },
	},
	mounted() {
		HTTP.get("all_type_format_tipe")
			.then((response) => this.setFormatType(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
		HTTP.get("event_all_limit")
			.then((response) => this.setEventContent(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
		HTTP.get("promo_all_limit")
			.then((response) => this.setPromoContent(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
	},
	computed: {
		filterArtikelUpdate: function() {
			if (!this.tFormat.length) {
				return this.eventNew.filter((newEvent) => {
					return newEvent.judul_artikel
						.toLowerCase()
						.match(this.updateSearch.toLowerCase());
				});
			} else {
				return this.eventNew.filter((newEvent) => {
					// console.log(newEvent.tags.match(2));
					return (
						// untuk array pakai ini
						// newEvent.format_tipe_id.includes(this.tFormat) &&
						this.tFormat.every((i) => newEvent.format_tipe_id.includes(i)) &&
						newEvent.judul_artikel
							.toLowerCase()
							.match(this.updateSearch.toLowerCase())
					);
					// return (product.price > 0 && product.price < this.range) ? product : '';
				});
			}
		},

		filterPromoUpdate: function() {
			if (!this.tFormat.length) {
				return this.promoNew.filter((newPromo) => {
					return newPromo.judul_artikel
						.toLowerCase()
						.match(this.updateSearch.toLowerCase());
				});
			} else {
				return this.promoNew.filter((newPromo) => {
					// console.log(newArtikel.format_tipe_id.includes(this.tFormat))
					return (
						// this.tFormat.includes(newPromo.format_tipe_id) &&
						this.tFormat.every((x) => newPromo.format_tipe_id.includes(x)) &&
						newPromo.judul_artikel
							.toLowerCase()
							.match(this.updateSearch.toLowerCase())
					);
				});
			}
		},
	},
};
</script>

<style></style>
