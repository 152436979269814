<template>
	<div class="fluid" style="padding:2%">
		<H2 class="text-center bold-stella jdl-prod"
			>Berbagai Pilihan Produk Stella siap <br />
			Menemani Berbagai Aktivitas</H2
		>
		<p class="text-center ph-prod">
			Hadirkan mood positif dengan rangkaian produk Stella
		</p>

		<div class="container">
			<div
				style="margin-top: 15%;margin-bottom: 15%"
				class="loading text-center mt-10"
				:class="{ hilang: loading }"
			>
				<img src="../assets/images/testing/load.gif" width="5%" />
			</div>
			<div :class="{ hilang: afterLoading }" class="container col-12 col-lg-12">
				<div class="row h-100">
					<!-- loop dari sini -->

					<div
						v-for="produkLiving in produkLivings"
						:key="produkLiving.id_living_space"
						class="living-card-all col-6 col-lg-3"
					>
						<div class="card shadow card-living">
							<div class="card-header-living">
								{{ produkLiving.nama_living_space }}
							</div>

							<img :src="produkLiving.url_banner_living_space" width="100%" />

							<div class="card-footer-living">
								<div class="row">
									<div class="col-12 col-lg-12 justify-content-between">
										<router-link to="/produk">Lihat Selengkapnya </router-link>
										<router-link to="/" style="float:right"
											><b-icon-arrow-right></b-icon-arrow-right
										></router-link>
									</div>
									<!-- <div class="col-2 col-lg-2">
										<router-link to="/"
											><b-icon-arrow-right></b-icon-arrow-right
										></router-link>
									</div> -->
								</div>
							</div>
						</div>
					</div>
					<!-- </div> -->
					<!-- sampai sini -->

					<!-- <div class="living-card-all col-lg-4">
						<div class="card shadow card-living-all ">
							<div class="card-header-living-all">
								<div class="row justify-content-between i-produk">
									<div class="col-10 col-lg-10">
										<router-link to="/produk"> Info Produk</router-link>
									</div>
									<div class="col-2 col-lg-2">
										<router-link to="/"
											><b-icon-plus-circle-fill></b-icon-plus-circle-fill
										></router-link>
									</div>
								</div>
							</div>
							<div
								class="card-body align-items-center d-flex justify-content-center card-bmobile"
							>
								<p class="par-living-all">
									Stella All In One Naturals Apple 70gr adalah pengharum ruangan
									yang praktis dan ekonomis yang memberikan suasana menyegarkan.
									Dapat dipakai sebagai refill Stella Blossom / kemasan kaleng
									lain. Dapatkan edisi terbatas ini dengan harga spesial.
								</p>
							</div>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
export default {
	name: "ProdukHome",
	data() {
		return {
			produkLivings: [],
			loading: false,
			afterLoading: true,
		};
	},
	methods: {
		setProduks(data) {
			this.produkLivings = data;
			this.loading = true;
			this.afterLoading = false;
		},
	},
	mounted() {
		HTTP.get("all_living_space_limit")
			.then((response) => this.setProduks(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
	},
};
</script>

<style></style>
