<template>
	<div class="fluid">
		<div class="container all-produk">
			<h5 class="bold-stella m-1">Tips</h5>
			<div class="input-group mb-3">
				<input
					v-model="tipsSearch"
					type="text"
					class="form-control txt-search"
					placeholder="Cari Tips untuk artikel"
					aria-label="Cari Tips"
					aria-describedby="basic-addon1"
					@keyup.enter="searchTips"
				/>
				<span class="input-group-text logo-search"
					><b-icon-search></b-icon-search
				></span>
			</div>

			<div class="row">
				<div class="col-12 mb-2 web-view">
					<b-button class="btnFilter" v-b-modal.modal-xl variant="outline-info"
						><b-icon icon="sliders"></b-icon> Filter</b-button
					>
				</div>
				<div class="col-lg-2 mobile-view">
					<p class="sub-judul m-1">Filter</p>

					<p v-b-toggle.collapse-3 class="sub-judul-1 m-1">Format <b-icon icon="caret-down-fill"></b-icon></p>
					<b-collapse
						v-for="newFT in formatTipeNew"
						:key="newFT.id_format_tipe"
						visible
						id="collapse-3"
						class="clp-filter"
					>
						<div class="chiller_cb">
							<input
								:id="newFT.id_format_tipe"
								type="checkbox"
								:value="newFT.id_format_tipe"
								v-model="tFormat"
								v-on:checked="filterArtikelTips"
							/>
							<label :for="newFT.id_format_tipe">{{
								newFT.nama_format_tipe
							}}</label>
							<span></span>
						</div>
					</b-collapse>
				</div>
				<div class="col-lg-10 tab-prod">
					<b-tabs content-class="mt-3">
						<b-tab title="Artikel" active>
							<div class="fluid mb-3">
								<div class="row text-center h-100">
									<div
										v-for="newArtikel in filterArtikelTips"
										:key="newArtikel.id_artikel"
										:class="{ hilang: afterLoading }"
										class="col-lg-4 text-center card-col-artikel "
									>
										<router-link
											class="shadow card-artikel box7 nav-link link-artikel"
											:to="'/artikel/' + newArtikel.slug"
										>
											<div class="card-add">
												<div class="card-body text-left ">
													<p class="card-title artikel-judul-list">
														{{ newArtikel.nama_kategori_artikel }}
													</p>
													<img
														class="card-img"
														:src="newArtikel.url_gambar_artikel"
														width="25%"
													/>
													<p class="card-title artikel-judul-list">
														{{ newArtikel.judul_artikel }}
													</p>
													<p class="card-text artikel-desk-list">
														{{ newArtikel.meta_desc }}
													</p>
												</div>
												<div class="card-artikel-footer">
													<small class="text-muted">{{
														newArtikel.tanggal_publish
													}}</small>
													<small class="text-muted"
														><router-link to="/"
															>Selengkapnya<b-icon-arrow-right
															></b-icon-arrow-right></router-link
													></small>
												</div>
											</div>
											<div class="box-content">
												<div class="card-content-show card-artikel-first ">
													<div class="card-body ">
														<p class="text-left first-pesan">
															{{ newArtikel.judul_artikel }}
														</p>
														<p class="text-left pesan-tanggal">
															{{ newArtikel.tanggal_publish }}
														</p>

														<p class="text-left first-desk">
															{{ newArtikel.meta_desc }}
														</p>
													</div>
												</div>
											</div>
										</router-link>
									</div>

									<div
										:class="{ hilang: loading }"
										class="col-lg-4 text-center card-col-artikel "
									>
										<div class="card shadow card-artikel artikel-list">
											<div class="card-body text-left ">
												<p class="info-skel">tips</p>
												<img
													class="card-img"
													src="../assets/images/testing/load.gif"
													width="10%"
												/>
												<p class="info-skel">
													Judul
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</b-tab>
						<b-tab title="Video">
							<div class="fluid mb-3">
								<div class="row text-center h-100">
									<!-- loop prod dari sini -->
									<div
										v-for="newVideo in videoNew"
										:key="newVideo.id_video"
										:class="{ hilang: afterLoadingvid }"
										class="col-lg-4 text-center"
									>
										<div class="">
											<div class="card-video ">
												<iframe
													width="100%"
													height="200"
													:src="newVideo.url_video"
													title="YouTube video player"
													frameborder="0"
													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
													allowfullscreen
												></iframe>
											</div>
											<div class="text-left mt-1 ml-1 text-video">
												<p>
													{{ newVideo.judul_video }}
												</p>
											</div>
										</div>
									</div>
									<!-- sampai sini  -->

									<div
										:class="{ hilang: loadingvid }"
										class="col-lg-12 text-center"
									>
										<div class="">
											<img
												class="card-img"
												src="../assets/images/testing/load.gif"
												style="width: 10%;!important"
											/>
										</div>
									</div>
								</div></div
						></b-tab>
					</b-tabs>
				</div>
			</div>
		</div>
		<b-modal
			content-class="filterMobile"
			id="modal-xl"
			size="xl"
			title="Extra Large Modal"
			hide-header
			hide-footer
		>
			<div class="col-lg-12">
				<p class="sub-judul m-1">Filter</p>

				<p v-b-toggle.collapse-3 class="sub-judul-1">Format <b-icon icon="caret-down-fill"></b-icon></p>
				<b-collapse
						v-for="newFT in formatTipeNew"
						:key="newFT.id_format_tipe"
						visible
						id="collapse-3"
						class="clp-filter"
					>
						<div class="chiller_cb">
							<input
								:id="newFT.id_format_tipe"
								type="checkbox"
								:value="newFT.id_format_tipe"
								v-model="tFormat"
								v-on:checked="filterArtikelTips"
							/>
							<label :for="newFT.id_format_tipe">{{
								newFT.nama_format_tipe
							}}</label>
							<span></span>
						</div>
					</b-collapse>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
export default {
	name: "TipsContent",
	data() {
		return {
			artikelNew: [],
			loading: false,
			afterLoading: true,
			videoNew: [],
			loadingvid: false,
			afterLoadingvid: true,
			formatTipeNew: [],
			tipsSearch: "",
			tFormat: [],
		};
	},
	methods: {
		setFormatType(dataFormat) {
			this.formatTipeNew = dataFormat;
		},
		setArtikelContent(data) {
			this.artikelNew = data;
			this.loading = true;
			this.afterLoading = false;
		},
		setVideoContent(datavideo) {
			this.videoNew = datavideo;
			this.loadingvid = true;
			this.afterLoadingvid = false;
		},
		searchTips() {
			// if (this.tFormat.length != 0) {
			// 	// console.log("ada isi"+this.tFormat);
			// 	// console.log(this.tFormat.values);
			// } else {
			// if (this.tipsSearch != "") {
			// 	this.loading = false;
			// 	this.loadingvid = false;
			// 	HTTP.get("artikel/tips/" + this.tipsSearch)
			// 		.then((response) => {
			// 			this.setArtikelContent(response.data);
			// 			this.loading = true;
			// 			this.afterLoading = false;
			// 		})
			// 		// .then((response)=> console.log(response.data))
			// 		.catch((error) => console.log(error));
			// 	HTTP.get("video/" + this.tipsSearch)
			// 		.then((response) => this.setVideoContent(response.data))
			// 		// .then((response)=> console.log(response.data))
			// 		.catch((error) => console.log(error));
			// 	this.afterLoading = true;
			// 	this.afterLoadingvid = true;
			// } else {
			// 	this.loading = false;
			// 	this.loadingvid = false;
			// 	HTTP.get("artikel_all").then((response) =>
			// 		this.setArtikelContent(response.data)
			// 	);
			// 	HTTP.get("video")
			// 		.then((response) => this.setVideoContent(response.data))
			// 		// .then((response)=> console.log(response.data))
			// 		.catch((error) => console.log(error));
			// 	this.afterLoading = true;
			// 	this.afterLoadingvid = true;
			// }
			// }
		},
		scroollToTop() {
			window.scrollTo(0, 0);
		},
	},
	mounted() {
		HTTP.get("all_type_format_tipe")
			.then((response) => this.setFormatType(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
		HTTP.get("artikel_all")
			.then((response) => this.setArtikelContent(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
		HTTP.get("video")
			.then((response) => this.setVideoContent(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
	},
	computed: {
		filterArtikelTips: function() {
			if (!this.tFormat.length) {
				return this.artikelNew.filter((newArtikel) => {
					return newArtikel.judul_artikel
						.toLowerCase()
						.match(this.tipsSearch.toLowerCase());
				});
			} else {
				return this.artikelNew.filter((newArtikel) => {
					// console.log(newEvent.tags.match(2));
					return (
						// untuk array pakai ini
						// newArtikel.format_tipe_id.includes(this.tFormat) &&
						this.tFormat.every((i) => newArtikel.format_tipe_id.includes(i)) &&
						newArtikel.judul_artikel
							.toLowerCase()
							.match(this.tipsSearch.toLowerCase())
					);
					// return (product.price > 0 && product.price < this.range) ? product : '';
				});
			}
		},
	},
};
</script>

<style></style>
