import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Produk from "../views/Produk.vue";
import Update from "../views/Update.vue";
import Tips from "../views/Tips.vue";
import ContactUs from "../views/ContactUs.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import LoginPage from "../views/LoginPage.vue";
import DetailArtikel from "../views/DetailArtikel.vue";
import DetailProduk from "../views/DetailProduk.vue";
import Profile from "../views/Profile.vue";
import Wishlist from "../views/Wishlist.vue";
import Voucher from "../views/Voucher.vue";
import Sitemaps from "../views/Sitemaps.vue";
import Mistdiffuser from "../views/Mistdiffuser.vue";
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    name: "Beranda",
    component: Home,
  },
  {
    path: "/produk",
    name: "Produk",
    component: Produk,
  },
  {
    path: "/update",
    name: "Update",
    component: Update,
  },
  {
    path: "/tips",
    name: "Tips",
    component: Tips,
  },
  {
    path: "/contact",
    name: "Hubungi Kami",
    component: ContactUs,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/Profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/wishlist",
    name: "Wishlist",
    component: Wishlist,
  },
  {
    path: "/voucher",
    name: "Voucher",
    component: Voucher,
  },
  {
    path: "/artikel/:slug",
    name: "Detail Artikel",
    component: DetailArtikel,
  },
  {
    path: "/produk-detail/:slug",
    name: "Detail Produk",
    component: DetailProduk,
  },
  {
    path: "/sitemap.xml/artikel",
    component: Sitemaps,
  },
  {
    path: "/mistdiffuser",
    component: Mistdiffuser,
  },
  // {
  // 	path: "/about",
  // 	name: "About",
  // 	// route level code-splitting
  // 	// this generates a separate chunk (about.[hash].js) for this route
  // 	// which is lazy-loaded when the route is visited.
  // 	component: () =>
  // 		import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = `Stella Air Freshener - ${to.name}`;
  // const descEl = document.querySelector('head meta[name="description"]');

  // descEl.setAttribute("content", "testing barus");
  next();
});

Vue.use(VueMeta, {
  keyName: "head",
});

export default router;
